const loaderReducer = (state, action) => {
  state = true;
  switch (action.type) {
    case 'LOADED':
      return false;
    default:
      return true;
  }
};

export default loaderReducer;
