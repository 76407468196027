const typesReducer = (state, action) => {
  state = { type: '', name: '', loader: true, status: true };
  switch (action.type) {
    case '':
      return state;
    default:
      return { type: action.type, name: action.name, loader: action.loader, status: action.status };
  }
};

export default typesReducer;
