import { filter, capitalize } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import { ShimmerTable } from 'react-shimmer-effects';
import { useState, useEffect } from 'react';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  CardContent,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Drawer,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
  Link,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

import axios from 'axios';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';

// utils
import { BASE_URL } from '../utils/data';
import { view } from '../utils/auth';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email Address', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

const classTypes = [
  { id: 'small', type: 'Small' },
  { id: 'medium', type: 'Medium' },
  { id: 'large', type: 'Large' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ViewDepartmentLevel() {
  const params = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [state, setState] = useState(false);

  const [loader, setLoader] = useState(true);

  const [showPassword, setShowPassword] = useState(false);

  const [newUser, setNewUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    type: '',
    password: 'password123',
  });

  const [msg, setMsg] = useState();
  const [deptLevel, setDeptLevel] = useState(null);

  const [token, setToken] = useState(view()._token);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const deleteDeptLevel = async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}admin/department/level/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setLoader(true);
      navigate(`/dashboard/department/${deptLevel.dept_code}`);
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const handleUpdateDeptLevel = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${BASE_URL}admin/department/level/${params.id}/update`, deptLevel, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      navigate(`/dashboard/department/${deptLevel.dept_code}`);
      setLoader(true);
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  useEffect(() => {
    async function fetchDeptLevel() {
      if (loader) {
        const response2 = await axios.get(`${BASE_URL}admin/department/level/${params.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setLoader(false);
        setDeptLevel(response2.data.data);
      }
    }

    fetchDeptLevel();
  }, [deptLevel, token, loader, params]);

  if (loader === true) {
    return (
      <div className="text-center p-5">
        <h3>Loading...</h3>
      </div>
    );
  }

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {`${deptLevel.level}`}
          </Typography>
          <div>
            <Button
              variant="contained"
              onClick={() => deleteDeptLevel(deptLevel.id)}
              color="error"
              // startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Delete
            </Button>
          </div>
        </Stack>

        <Stack mb={3}>{msg}</Stack>

        <Card>
          <CardContent>
            <Stack spacing={3}>
              <Typography variant="h4" component="h2">
                Update Department Level
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {`${deptLevel.semester} semester (${deptLevel.session})`}
              </Typography>

              <form onSubmit={handleUpdateDeptLevel}>
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    label="Number of students"
                    id="no_of_students"
                    value={deptLevel.no_of_students}
                    onChange={(e) => setDeptLevel({ ...deptLevel, no_of_students: e.target.value })}
                    required
                  />
                  <TextField
                    fullWidth
                    label="HOD GSM Number"
                    id="hod_gsm_no"
                    value={deptLevel.hod_gsm_no}
                    onChange={(e) => setDeptLevel({ ...deptLevel, hod_gsm_no: e.target.value })}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Semester"
                    id="semester"
                    value={deptLevel.semester}
                    onChange={(e) => setDeptLevel({ ...deptLevel, semester: e.target.value })}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Session"
                    id="session"
                    value={deptLevel.session}
                    onChange={(e) => setDeptLevel({ ...deptLevel, session: e.target.value })}
                    required
                  />

                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Mode of Study</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={deptLevel.ft}
                      onChange={(e) => setDeptLevel({ ...deptLevel, ft: e.target.value })}
                      required
                    >
                      <FormControlLabel control={<Radio />} value="true" label="Full-Time" />
                      <FormControlLabel value="false" control={<Radio />} label="Part-Time" />
                    </RadioGroup>
                  </FormControl>

                  <TextField
                    fullWidth
                    label="Level"
                    id="level"
                    value={deptLevel.level}
                    onChange={(e) => setDeptLevel({ ...deptLevel, level: e.target.value })}
                    required
                  />

                  <TextField
                    fullWidth
                    label="Lecture Duration"
                    id="lecture_duration"
                    value={deptLevel.lecture_duration}
                    onChange={(e) => setDeptLevel({ ...deptLevel, lecture_duration: e.target.value })}
                    required
                  />
                </Stack>
                {/* <Stack alignItems="left" spacing={3}> */}

                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={3}>
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </Stack>
              </form>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
