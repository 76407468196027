import { combineReducers } from 'redux';
import typesReducer from './types';
import itemTypesReducer from './itemTypes';
import collectionTypes from './collectionTypes';
import loaderReducer from './loader';
// import categoriesReducer from '../features/categories/categoriesSlice';

const allReducers = combineReducers({
  type: typesReducer,
  itemtype: itemTypesReducer,
  collectiontype: collectionTypes,
  loader: loaderReducer,
  // categories: categoriesReducer,
});

export default allReducers;
