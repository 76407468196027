import { filter, capitalize } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import { ShimmerTable } from 'react-shimmer-effects';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  CardContent,
  CardMedia,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Drawer,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
  Link,
} from '@mui/material';

import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';

import axios from 'axios';
import { useTheme } from '@mui/material/styles';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';

// utils
import { BASE_URL } from '../utils/data';
import { view } from '../utils/auth';

// ----------------------------------------------------------------------
export default function DeptCsvImport() {
  const [state, setState] = useState(false);

  const [loader, setLoader] = useState(null);

  const [msg, setMsg] = useState();
  const [alert, setAlert] = useState();
  const [csvFile, setCsvFile] = useState(null);
  const [alert2, setAlert2] = useState();
  const [alert3, setAlert3] = useState();
  const [csvFile2, setCsvFile2] = useState(null);
  const [csvFile3, setCsvFile3] = useState(null);

  const [token, setToken] = useState(view()._token);

  const handleCsv = async (type, file) => {
    console.log(file);
    setLoader(true);
    if (type === 'level') {
      setAlert(
        <Button variant="outlined" color="primary" disabled size="small">
          {file[0].name}
        </Button>
      );
      setCsvFile(file[0]);
    } else if (type === 'input') {
      setAlert2(
        <Button variant="outlined" color="primary" disabled size="small">
          {file[0].name}
        </Button>
      );
      setCsvFile2(file[0]);
    } else if (type === 'dept') {
      setAlert3(
        <Button variant="outlined" color="primary" disabled size="small">
          {file[0].name}
        </Button>
      );
      setCsvFile3(file[0]);
    }
  };

  const handleSubmitCsv = async (type) => {
    const formdata = new FormData();
    let link = '';
    if (type === 'level') {
      formdata.append('doc', csvFile);
      link = `${BASE_URL}admin/department/${type}/data/import`;
    } else if (type === 'input') {
      formdata.append('doc', csvFile2);
      link = `${BASE_URL}admin/department/${type}/data/import`;
    } else if (type === 'dept') {
      formdata.append('doc', csvFile3);
      link = `${BASE_URL}admin/department/data/import`;
    }

    try {
      let response = null;

      response = await axios.post(link, formdata, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(
        <Alert
          action={
            <Button component={RouterLink} to={'/dashboard/departments'} color="inherit" size="small">
              View all
            </Button>
          }
        >
          {response.data.msg}
        </Alert>
      );
      setCsvFile('');
      setCsvFile2('');
      setCsvFile3('');
      setAlert('');
      setAlert2('');
      setAlert3('');
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setState(false);
      setLoader(true);
    } catch (error) {
      console.log(error);
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const handleDropDeptLevels = async () => {
    try {
      const response = await axios.get(`${BASE_URL}admin/department/level/truncate/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setLoader(true);
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };
  const handleDropDeptInputs = async () => {
    try {
      const response = await axios.get(`${BASE_URL}admin/department/input/truncate/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setLoader(true);
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const theme = useTheme();

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Import Departments' file
          </Typography>
          {/* <div>
            <Button
              variant="contained"
              onClick={() => handleDropDeptLevels()}
              color="error"
              startIcon={<Iconify icon="eva:archive-fill" />}
            >
              Truncate dept levels
            </Button>
            &nbsp;
            <Button
              variant="contained"
              onClick={() => handleDropDeptInputs()}
              color="error"
              startIcon={<Iconify icon="eva:archive-fill" />}
            >
              Truncate dept inputs
            </Button>
          </div> */}
        </Stack>

        <Stack>{msg}</Stack>
        <br />
        <Card>
          <CardContent>
            <center>
              <img src="/static/csv.png" width="200" alt="csv" />
              <br />
              <Typography variant="subtitle1">Upload a csv file</Typography>
              <br />
              <Button
                variant="contained"
                color="success"
                component="label"
                startIcon={<Iconify icon="eva:file-text-outline" />}
              >
                Select file
                <input hidden accept=".csv" type="file" onChange={(e) => handleCsv('dept', e.target.files)} />
              </Button>
              &nbsp;
              <Button variant="contained" type="submit" onClick={() => handleSubmitCsv('dept')}>
                Save
              </Button>
              <br />
              <br />
              {alert3}
            </center>
          </CardContent>
        </Card>
        <br />
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Import Department Levels' file
          </Typography>
        </Stack>

        <Stack>{msg}</Stack>
        <br />
        <Card>
          <CardContent>
            <center>
              <img src="/static/csv.png" width="200" alt="csv" />
              <br />
              <Typography variant="subtitle1">Upload a csv file</Typography>
              <br />
              <Button
                variant="contained"
                color="success"
                component="label"
                startIcon={<Iconify icon="eva:file-text-outline" />}
              >
                Select file
                <input hidden accept=".csv" type="file" onChange={(e) => handleCsv('level', e.target.files)} />
              </Button>
              &nbsp;
              <Button variant="contained" type="submit" onClick={() => handleSubmitCsv('level')}>
                Save
              </Button>
              <br />
              <br />
              {alert}
            </center>
          </CardContent>
        </Card>
        <br />
        <br />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Import Department Inputs' file
          </Typography>
        </Stack>

        <Stack>{msg}</Stack>
        <br />
        <Card>
          <CardContent>
            <center>
              <img src="/static/csv.png" width="200" alt="csv" />
              <br />
              <Typography variant="subtitle1">Upload a csv file</Typography>
              <br />
              <Button
                variant="contained"
                color="success"
                component="label"
                startIcon={<Iconify icon="eva:file-text-outline" />}
              >
                Select file
                <input hidden accept=".csv" type="file" onChange={(e) => handleCsv('input', e.target.files)} />
              </Button>
              &nbsp;
              <Button variant="contained" type="submit" onClick={() => handleSubmitCsv('input')}>
                Save
              </Button>
              <br />
              <br />
              {alert2}
            </center>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
