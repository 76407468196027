import { filter, capitalize } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import { ShimmerTable } from 'react-shimmer-effects';
import { useState, useEffect } from 'react';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  CardContent,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Drawer,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
  Link,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

import axios from 'axios';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';

// utils
import { BASE_URL, session } from '../utils/data';
import { view } from '../utils/auth';
import { lecturerCodes } from '../utils/timetable';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'course_title', label: 'Course Title', alignRight: false },
  { id: 'course_code', label: 'Course Code', alignRight: false },
  { id: 'course_unit', label: 'Course Unit', alignRight: false },
  { id: 'lecturer', label: 'Lecturer(s)', alignRight: false },
  { id: 'level', label: 'Level', alignRight: false },
  { id: 'lecture_hrs', label: 'Lecture Hrs', alignRight: false },
  { id: 'practical_hrs', label: 'Practical Hrs', alignRight: false },
  { id: 'tutorial_hrs', label: 'Tutorial Hrs', alignRight: false },
  { id: 'technologist', label: 'Technologist', alignRight: false },
  { id: 'nature_of_practical', label: 'Nature of practical', alignRight: false },
  { id: '' },
];

const TABLE_HEAD_LEVEL = [
  { id: 'level', label: 'Level', alignRight: false },
  { id: 'semester', label: 'Semester', alignRight: false },
  { id: 'session', label: 'Session', alignRight: false },
  { id: 'hod_gsm_no', label: 'HOD GSM No.', alignRight: false },
  { id: 'ft', label: 'FT/PT', alignRight: false },
  { id: 'lecture_duration', label: 'Lecture Duration', alignRight: false },
  { id: 'no_of_students', label: 'No of students', alignRight: false },
  { id: '' },
];

const TABLE_HEAD_OUTPUT = [
  { id: 'level', label: 'Level', alignRight: false },
  { id: 'class', label: 'Classroom', alignRight: false },
  { id: 'course_code', label: 'Course Code', alignRight: false },
  { id: 'day', label: 'Day', alignRight: false },
  { id: 'time', label: 'Time', alignRight: false },
  { id: 'lecturer_code', label: 'Lecturer Code', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applyDeptLevelSortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_d) =>
        _d.hod_gsm_no.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.semester.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.session.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.ft === parseInt(query, 2) ||
        _d.level.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function applyDeptInputSortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(
      array,
      (_d) =>
        _d.course_title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.course_unit === parseInt(query, 10) ||
        _d.course_code.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.lecture_hrs === parseInt(query, 10) ||
        _d.department_level.level.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.lecturer_id.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.nature_of_practical.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.technologist === parseInt(query, 10)
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function applyDeptOutputSortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(
      array,
      (_d) =>
        _d.department_level.level.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.department_input.course_code.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.classroom.venue.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.lecturer.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.day.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.time.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.lecturer.id === parseInt(query, 10)
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ViewDepartment() {
  const params = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [pageB, setPageB] = useState(0);
  const [pageC, setPageC] = useState(0);

  const [order, setOrder] = useState('asc');
  const [orderB, setOrderB] = useState('asc');
  const [orderC, setOrderC] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [selectedB, setSelectedB] = useState([]);
  const [selectedC, setSelectedC] = useState([]);

  const [orderBy, setOrderBy] = useState('name');
  const [orderByB, setOrderByB] = useState('name');
  const [orderByC, setOrderByC] = useState('name');

  const [filterDeptInput, setFilterDeptInput] = useState('');
  const [filterDeptLevel, setFilterDeptLevel] = useState('');
  const [filterDeptOutput, setFilterDeptOutput] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rowsPerPage2, setRowsPerPage2] = useState(5);
  const [rowsPerPage3, setRowsPerPage3] = useState(5);

  const [state, setState] = useState(false);
  const [stateB, setStateB] = useState(false);
  const [stateC, setStateC] = useState(false);

  const [loader, setLoader] = useState(true);

  const [showPassword, setShowPassword] = useState(false);

  const [newUser, setNewUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    type: '',
    password: 'password123',
  });

  const [newDeptInput, setNewDeptInput] = useState({
    course_title: '',
    course_unit: '',
    course_code: '',
    lecture_hrs: null,
    tutorial_hrs: null,
    practical_hrs: null,
    lecturer_id: '',
    technologist: '',
    nature_of_practical: '',
    dept_level_id: '',
  });

  const [newDeptLevel, setNewDeptLevel] = useState({
    dept_code: '',
    no_of_students: '',
    hod_gsm_no: '',
    semester: '',
    session: '',
    ft: true,
    level: '',
    lecture_duration: '',
  });

  const [msg, setMsg] = useState();
  const [dept, setDept] = useState(null);
  const [lecturers, setLecturers] = useState([]);
  const [deptLevels, setDeptLevels] = useState([]);
  const [deptInputs, setDeptInputs] = useState([]);
  const [deptOutputs, setDeptOutputs] = useState([]);

  const [token, setToken] = useState(view()._token);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = deptLevels.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSortB = (event, property) => {
    const isAsc = orderByB === property && orderB === 'asc';
    setOrderB(isAsc ? 'desc' : 'asc');
    setOrderByB(property);
  };

  const handleSelectAllClick2 = (event) => {
    if (event.target.checked) {
      const newSelecteds = deptInputs.map((n) => n.name);
      setSelectedB(newSelecteds);
      return;
    }
    setSelectedB([]);
  };

  const handleRequestSortC = (event, property) => {
    const isAsc = orderByC === property && orderC === 'asc';
    setOrderC(isAsc ? 'desc' : 'asc');
    setOrderByC(property);
  };

  const handleSelectAllClickC = (event) => {
    if (event.target.checked) {
      const newSelecteds = deptOutputs.map((n) => n.name);
      setSelectedC(newSelecteds);
      return;
    }
    setSelectedC([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleClickB = (event, name) => {
    const selectedIndex = selectedB.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedB, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedB.slice(1));
    } else if (selectedIndex === selectedB.length - 1) {
      newSelected = newSelected.concat(selectedB.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedB.slice(0, selectedIndex), selectedB.slice(selectedIndex + 1));
    }
    setSelectedB(newSelected);
  };

  const handleClickC = (event, name) => {
    const selectedIndex = selectedC.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedC, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedC.slice(1));
    } else if (selectedIndex === selectedC.length - 1) {
      newSelected = newSelected.concat(selectedC.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedC.slice(0, selectedIndex), selectedC.slice(selectedIndex + 1));
    }
    setSelectedC(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePageB = (event, newPage) => {
    setPageB(newPage);
  };

  const handleChangeRowsPerPageB = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPageB(0);
  };

  const handleChangePageC = (event, newPage) => {
    setPageC(newPage);
  };

  const handleChangeRowsPerPage3 = (event) => {
    setRowsPerPage3(parseInt(event.target.value, 10));
    setPageC(0);
  };

  const handleFilterByDeptInput = (event) => {
    setFilterDeptInput(event.target.value);
  };

  const handleFilterByDeptLevel = (event) => {
    setFilterDeptLevel(event.target.value);
  };

  const handleFilterByDeptOutput = (event) => {
    setFilterDeptOutput(event.target.value);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const toggleDrawerB = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setStateB(open);
  };

  const toggleDrawerC = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setStateC(open);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - deptLevels.length) : 0;
  const emptyRows2 = pageB > 0 ? Math.max(0, (1 + pageB) * rowsPerPage2 - deptInputs.length) : 0;
  const emptyRows3 = pageC > 0 ? Math.max(0, (1 + pageC) * rowsPerPage3 - deptOutputs.length) : 0;

  const filteredDeptInputs = applyDeptInputSortFilter(deptInputs, getComparator(order, orderBy), filterDeptInput);
  const filteredDeptLevels = applyDeptLevelSortFilter(deptLevels, getComparator(orderB, orderByB), filterDeptLevel);
  const filteredDeptOutputs = applyDeptOutputSortFilter(deptOutputs, getComparator(orderC, orderByC), filterDeptOutput);

  const isDeptInputsNotFound = filteredDeptInputs.length === 0;
  const isDeptLevelsNotFound = filteredDeptLevels.length === 0;
  const isDeptOutputsNotFound = filteredDeptOutputs.length === 0;

  const deleteDepartment = async (id) => {
    try {
      if (window.confirm('Are you sure you want to perform this irreversible action?')) {
        const response = await axios.delete(`${BASE_URL}admin/department/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMsg(<Alert severity="success">{response.data.msg}</Alert>);
        setTimeout(() => {
          setMsg('');
        }, 5000);
        setLoader(true);
        navigate('/dashboard/departments');
      }
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const deleteDepartmentInputsByDeptCode = async () => {
    try {
      if (window.confirm('Are you sure you want to perform this irreversible action?')) {
        const response = await axios.delete(`${BASE_URL}admin/department/input/all/${params.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMsg(<Alert severity="success">{response.data.msg}</Alert>);
        setTimeout(() => {
          setMsg('');
        }, 5000);
        setLoader(true);
        navigate(`/dashboard/department/${params.id}`);
      }
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const handleUpdateDepartment = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${BASE_URL}admin/department/${params.id}/update`, dept, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setLoader(true);
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const handleNewDeptInput = async (e) => {
    e.preventDefault();
    try {
      let response = null;
      if (!newDeptInput.tutorial_hrs) delete newDeptInput.tutorial_hrs;
      console.log(newDeptInput);
      response = await axios.post(`${BASE_URL}admin/department/input/create`, newDeptInput, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setNewDeptInput({
        ...newDeptInput,
        course_title: '',
        course_unit: '',
        course_code: '',
        lecture_hrs: null,
        practical_hrs: null,
        tutorial_hrs: null,
        lecturer_id: '',
        technologist: '',
        nature_of_practical: '',
        dept_level_id: '',
      });
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setState(false);
      setLoader(true);
    } catch (error) {
      console.log(error);
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const handleNewDeptLevel = async (e) => {
    e.preventDefault();
    try {
      let response = null;
      response = await axios.post(`${BASE_URL}admin/department/level/create`, newDeptLevel, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setNewDeptLevel({
        ...newDeptLevel,
        no_of_students: '',
        hod_gsm_no: '',
        semester: '',
        session: '',
        ft: '',
        level: '',
        lecture_duration: '',
      });
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setStateB(false);
      setLoader(true);
    } catch (error) {
      console.log(error);
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  useEffect(() => {
    async function fetchDepartment() {
      if (loader) {
        const response2 = await axios.get(`${BASE_URL}admin/lecturer/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLecturers(response2.data.data);

        const response = await axios.get(`${BASE_URL}admin/department/${params.id}/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const response3 = await axios.get(`${BASE_URL}admin/department/level`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const response4 = await axios.get(`${BASE_URL}admin/department/input`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const response5 = await axios.get(`${BASE_URL}admin/allocation/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setNewDeptLevel({
          ...newDeptLevel,
          dept_code: response.data.data.id,
        });
        setDeptLevels(response3.data.data.filter((data) => data.dept_code === response.data.data.id));
        setDeptInputs(response4.data.data.filter((data) => data.dept_code === response.data.data.id));

        // console.log(response5.data.data);
        if (response5.data.data.length > 0) {
          setDeptOutputs(
            response5.data.data.filter((data) => data.department_input.dept_code === response.data.data.id)
          );
        }
        // console.log(session);
        console.log(response5.data.data.filter((data) => data.department_input.dept_code === response.data.data.id));
        setDept(response.data.data);

        setLoader(false);

        // console.log(
        //   response5.data.data.filter(
        //     (data) =>
        //       data.department_level.dept_code === response.data.data.id && data.department_level.session === '2021/2022'
        //   )
        // );
      }
    }

    fetchDepartment();
  }, [dept, deptLevels, deptInputs, deptOutputs, newDeptLevel, lecturers, token, loader, params]);

  if (loader === true) {
    return (
      <div className="text-center p-5">
        <h3>Loading...</h3>
      </div>
    );
  }

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {`${dept.name}`}
          </Typography>
          <div>
            <Button
              variant="contained"
              onClick={toggleDrawerB('right', true)}
              startIcon={<Iconify icon="eva:plus-fill" />}
              color="success"
            >
              Create Level
            </Button>{' '}
            &nbsp;
            <Button
              variant="contained"
              onClick={toggleDrawer('right', true)}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Create Input
            </Button>{' '}
            &nbsp;
            {/* <Button
              variant="contained"
              onClick={() => deleteDepartment(dept.id)}
              color="error"
              // startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Delete
            </Button> */}
          </div>
        </Stack>

        <Stack mb={3}>{msg}</Stack>

        <Drawer anchor={'right'} open={state} onClose={toggleDrawer('right', false)}>
          <Box
            sx={{ width: 400 }}
            role="presentation"
            // onClick={toggleDrawer('right', false)}
            // onKeyDown={toggleDrawer('right', false)}
          >
            <Container style={{ paddingTop: 20 }}>
              <Stack spacing={3}>
                <Typography variant="h4" component="h2">
                  Create Department Input
                </Typography>

                {msg}
                <form onSubmit={handleNewDeptInput}>
                  <Stack spacing={2}>
                    <TextField
                      fullWidth
                      label="Course Title"
                      id="course_title"
                      value={newDeptInput.course_title}
                      onChange={(e) => setNewDeptInput({ ...newDeptInput, course_title: e.target.value })}
                      required
                    />
                    <TextField
                      fullWidth
                      label="Course Unit"
                      id="course_unit"
                      value={newDeptInput.course_unit}
                      onChange={(e) => setNewDeptInput({ ...newDeptInput, course_unit: e.target.value })}
                      required
                    />
                    <TextField
                      fullWidth
                      label="Course Code"
                      id="course_code"
                      value={newDeptInput.course_code}
                      onChange={(e) => setNewDeptInput({ ...newDeptInput, course_code: e.target.value })}
                      required
                    />
                    <TextField
                      fullWidth
                      label="Lecture Hours"
                      id="lecture_hrs"
                      value={newDeptInput.lecture_hrs}
                      onChange={(e) => setNewDeptInput({ ...newDeptInput, lecture_hrs: e.target.value })}
                      required
                    />
                    <TextField
                      fullWidth
                      label="Practical Hours"
                      id="practical_hrs"
                      value={newDeptInput.practical_hrs}
                      onChange={(e) => setNewDeptInput({ ...newDeptInput, practical_hrs: e.target.value })}
                    />
                    <TextField
                      fullWidth
                      label="Tutorial Hours"
                      id="tutorial_hrs"
                      value={newDeptInput.tutorial_hrs}
                      onChange={(e) => setNewDeptInput({ ...newDeptInput, tutorial_hrs: e.target.value })}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="type-label">Lecturer</InputLabel>
                      <Select
                        labelId="lecturer-label"
                        id="lecturer-label"
                        value={newDeptInput.lecturer_id}
                        label="Lecturer"
                        onChange={(e) => setNewDeptInput({ ...newDeptInput, lecturer_id: e.target.value })}
                        required
                      >
                        {lecturers.map(
                          (lecturer) =>
                            lecturer.type === 'lecturer' &&
                            lecturer.dept_code === dept.id && (
                              <MenuItem key={lecturer.id} value={lecturer.id}>
                                {lecturer.name} ({lecturer.dept_code})
                              </MenuItem>
                            )
                        )}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="technologist-label">Technologist</InputLabel>
                      <Select
                        labelId="technologist-label"
                        id="technologist-label"
                        value={newDeptInput.technologist}
                        label="Technologist"
                        onChange={(e) => setNewDeptInput({ ...newDeptInput, technologist: e.target.value })}
                      >
                        {lecturers.map(
                          (lecturer) =>
                            lecturer.type === 'technologist' &&
                            lecturer.dept_code === dept.id && (
                              <MenuItem key={lecturer.id} value={lecturer.id}>
                                {lecturer.name} ({lecturer.dept_code})
                              </MenuItem>
                            )
                        )}
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      label="Nature of Practical"
                      id="nature_of_practical"
                      value={newDeptInput.nature_of_practical}
                      onChange={(e) => setNewDeptInput({ ...newDeptInput, nature_of_practical: e.target.value })}
                      required
                    />
                    <FormControl fullWidth>
                      <InputLabel id="type-label">Level</InputLabel>
                      <Select
                        labelId="deptlevel-label"
                        id="deptlevel-label"
                        value={newDeptInput.dept_level_id}
                        label="Level"
                        onChange={(e) => setNewDeptInput({ ...newDeptInput, dept_level_id: e.target.value })}
                        required
                      >
                        {deptLevels.map(
                          (level) =>
                            level.dept_code === dept.id && (
                              <MenuItem key={level.id} value={level.id}>
                                {level.level} {level.ft ? 'FT' : 'PT'}({level.session} {level.semester} semester)
                              </MenuItem>
                            )
                        )}
                      </Select>
                    </FormControl>
                  </Stack>
                  {/* <Stack alignItems="left" spacing={3}> */}

                  <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={3}>
                    <Button variant="contained" type="submit">
                      Create
                    </Button>
                  </Stack>
                </form>
              </Stack>
            </Container>
          </Box>
        </Drawer>

        {/* Drawer 2 */}
        <Drawer anchor={'right'} open={stateB} onClose={toggleDrawerB('right', false)}>
          <Box
            sx={{ width: 400 }}
            role="presentation"
            // onClick={toggleDrawer('right', false)}
            // onKeyDown={toggleDrawer('right', false)}
          >
            <Container style={{ paddingTop: 20 }}>
              <Stack spacing={3}>
                <Typography variant="h4" component="h2">
                  Create Department Level
                </Typography>

                {msg}
                <form onSubmit={handleNewDeptLevel}>
                  <Stack spacing={2}>
                    <TextField
                      fullWidth
                      label="Number of students"
                      id="no_of_students"
                      value={newDeptLevel.no_of_students}
                      onChange={(e) => setNewDeptLevel({ ...newDeptLevel, no_of_students: e.target.value })}
                      required
                    />
                    <TextField
                      fullWidth
                      label="HOD GSM Number"
                      id="hod_gsm_no"
                      value={newDeptLevel.hod_gsm_no}
                      onChange={(e) => setNewDeptLevel({ ...newDeptLevel, hod_gsm_no: e.target.value })}
                      required
                    />
                    <TextField
                      fullWidth
                      label="Semester"
                      id="semester"
                      value={newDeptLevel.semester}
                      onChange={(e) => setNewDeptLevel({ ...newDeptLevel, semester: e.target.value })}
                      required
                    />
                    <TextField
                      fullWidth
                      label="Session"
                      id="session"
                      value={newDeptLevel.session}
                      onChange={(e) => setNewDeptLevel({ ...newDeptLevel, session: e.target.value })}
                      required
                    />

                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">Mode of Study</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={newDeptLevel.ft}
                        onChange={(e) => setNewDeptLevel({ ...newDeptLevel, ft: e.target.value })}
                        required
                      >
                        <FormControlLabel control={<Radio />} value="true" label="Full-Time" />
                        <FormControlLabel value="false" control={<Radio />} label="Part-Time" />
                      </RadioGroup>
                    </FormControl>

                    <TextField
                      fullWidth
                      label="Level"
                      id="level"
                      value={newDeptLevel.level}
                      onChange={(e) => setNewDeptLevel({ ...newDeptLevel, level: e.target.value })}
                      required
                    />

                    <TextField
                      fullWidth
                      label="Lecture Duration"
                      id="lecture_duration"
                      value={newDeptLevel.lecture_duration}
                      onChange={(e) => setNewDeptLevel({ ...newDeptLevel, lecture_duration: e.target.value })}
                      required
                    />
                  </Stack>
                  {/* <Stack alignItems="left" spacing={3}> */}

                  <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={3}>
                    <Button variant="contained" type="submit">
                      Create
                    </Button>
                  </Stack>
                </form>
              </Stack>
            </Container>
          </Box>
        </Drawer>

        <Card>
          <CardContent>
            <Stack spacing={3}>
              <Typography variant="h4" component="h2">
                Update Department
              </Typography>

              <form onSubmit={handleUpdateDepartment}>
                <Stack spacing={2}>
                  <TextField
                    label="Code"
                    id="id"
                    value={dept.id}
                    onChange={(e) => setDept({ ...dept, id: e.target.value })}
                    required
                    disabled
                  />

                  <TextField
                    label="Name"
                    id="name"
                    value={dept.name}
                    onChange={(e) => setDept({ ...dept, name: e.target.value })}
                    required
                  />
                </Stack>

                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={3}>
                  <Button variant="contained" type="submit">
                    Update
                  </Button>
                </Stack>
              </form>
            </Stack>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <Typography variant="h4" component="h2">
                Department Levels
              </Typography>

              {loader === true ? (
                <ShimmerTable row={5} col={5} />
              ) : (
                <>
                  <UserListToolbar
                    numSelected={selected.length}
                    filterName={filterDeptLevel}
                    onFilterName={handleFilterByDeptLevel}
                  />

                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <UserListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD_LEVEL}
                          rowCount={deptLevels.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {filteredDeptLevels.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const {
                              id,
                              level,
                              session,
                              semester,
                              courseOption,
                              hodGsmNo,
                              noOfStudents,
                              ft,
                              lectureDuration,
                            } = camelcaseKeys(row);
                            const isItemSelected = selected.indexOf(id) !== -1;

                            return (
                              <TableRow
                                hover
                                key={id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                                </TableCell>
                                <TableCell component="th" scope="row" padding="10">
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    {/* <Avatar alt={name} src={avatarUrl} /> */}
                                    <Typography variant="subtitle2" noWrap>
                                      {level} {courseOption !== null && courseOption}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row" padding="20">
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography variant="subtitle2" noWrap>
                                      {semester}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row" padding="10">
                                  <Typography variant="subtitle2" noWrap>
                                    {session}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" padding="10">
                                  <Typography variant="subtitle2" noWrap>
                                    {hodGsmNo}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" padding="none">
                                  <Typography variant="subtitle2" noWrap>
                                    {ft ? 'Full-Time' : 'Part-Time'}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" padding="10">
                                  <Stack direction="row" alignItems="left" spacing={2}>
                                    <Typography variant="subtitle2" noWrap>
                                      {lectureDuration}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row" padding="10">
                                  <Typography variant="subtitle2" noWrap>
                                    {noOfStudents}
                                  </Typography>
                                </TableCell>

                                <TableCell align="right">
                                  <UserMoreMenu type="department level" identity={id} />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={9} />
                            </TableRow>
                          )}
                        </TableBody>

                        {isDeptLevelsNotFound && (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                                <SearchNotFound searchQuery={filterDeptLevel} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={deptLevels.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}
            </Stack>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" component="h2">
                  Department Inputs
                </Typography>
                {/* <Button
                  variant="contained"
                  onClick={deleteDepartmentInputsByDeptCode}
                  startIcon={<Iconify icon="eva:trash-fill" />}
                  color="error"
                >
                  Delete Inputs
                </Button>{' '} */}
              </Stack>

              {loader === true ? (
                <ShimmerTable row={5} col={5} />
              ) : (
                <>
                  <UserListToolbar
                    numSelected={selectedB.length}
                    filterName={filterDeptInput}
                    onFilterName={handleFilterByDeptInput}
                  />

                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <UserListHead
                          order={orderB}
                          orderBy={orderByB}
                          headLabel={TABLE_HEAD}
                          rowCount={deptInputs.length}
                          numSelected={selectedB.length}
                          onRequestSort={handleRequestSortB}
                          onSelectAllClick={handleSelectAllClick2}
                        />
                        <TableBody>
                          {filteredDeptInputs
                            .slice(pageB * rowsPerPage2, pageB * rowsPerPage2 + rowsPerPage2)
                            .map((row) => {
                              // const { id, name, role, status, company, avatarUrl, isVerified } = camelCase(row);
                              const {
                                id,
                                courseTitle,
                                courseUnit,
                                courseCode,
                                courseOption,
                                lectureHrs,
                                practicalHrs,
                                lecturer,
                                lecturerId,
                                technologist,
                                natureOfPractical,
                                tutorialHrs,
                                departmentLevel,
                                technologistName,
                              } = camelcaseKeys(row);
                              const isItemSelected = selectedB.indexOf(courseTitle) !== -1;

                              return (
                                <TableRow
                                  hover
                                  key={id}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      checked={isItemSelected}
                                      onChange={(event) => handleClickB(event, courseTitle)}
                                    />
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                      {/* <Avatar alt={name} src={avatarUrl} /> */}
                                      <Typography variant="subtitle2" noWrap>
                                        {courseTitle}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="20">
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                      <Typography variant="subtitle2" noWrap>
                                        {courseCode}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Typography variant="subtitle2" noWrap>
                                      {courseUnit}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Typography variant="subtitle2" noWrap>
                                      {!lecturer ? '-' : `${lecturerId}`}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="none">
                                    <Typography variant="subtitle2" noWrap>
                                      {!departmentLevel
                                        ? '-'
                                        : `${departmentLevel.level} ${departmentLevel.ft ? 'FT' : 'PT'} ${
                                            departmentLevel.course_option
                                          }`}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Stack direction="row" alignItems="left" spacing={2}>
                                      <Typography variant="subtitle2" noWrap>
                                        {!lectureHrs ? '-' : lectureHrs}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Typography variant="subtitle2" noWrap>
                                      {!practicalHrs ? '-' : practicalHrs}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Typography variant="subtitle2" noWrap>
                                      {!tutorialHrs ? '-' : tutorialHrs}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Typography variant="subtitle2" noWrap>
                                      {!technologistName ? '-' : technologistName.name}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Typography variant="subtitle2" noWrap>
                                      {natureOfPractical}
                                    </Typography>
                                  </TableCell>

                                  <TableCell align="right">
                                    <UserMoreMenu type="department input" identity={id} />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows2 > 0 && (
                            <TableRow style={{ height: 53 * emptyRows2 }}>
                              <TableCell colSpan={12} />
                            </TableRow>
                          )}
                        </TableBody>

                        {isDeptInputsNotFound && (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                                <SearchNotFound searchQuery={filterDeptInput} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={deptInputs.length}
                    rowsPerPage={rowsPerPage2}
                    page={pageB}
                    onPageChange={handleChangePageB}
                    onRowsPerPageChange={handleChangeRowsPerPageB}
                  />
                </>
              )}
            </Stack>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" component="h2">
                  Department Outputs
                </Typography>
                <div>
                  <Button
                    variant="contained"
                    // onClick={toggleDrawerB('right', true)}
                    to={`/download/department/${dept.id}/pdf`}
                    component={RouterLink}
                    startIcon={<Iconify icon="eva:download-fill" />}
                  >
                    Download
                  </Button>
                </div>
              </Stack>

              {loader === true ? (
                <ShimmerTable row={5} col={5} />
              ) : (
                <>
                  <UserListToolbar
                    numSelected={selectedC.length}
                    filterName={filterDeptOutput}
                    onFilterName={handleFilterByDeptOutput}
                  />

                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <UserListHead
                          order={orderC}
                          orderBy={orderByC}
                          headLabel={TABLE_HEAD_OUTPUT}
                          rowCount={deptOutputs.length}
                          numSelected={selectedC.length}
                          onRequestSort={handleRequestSortC}
                          onSelectAllClick={handleSelectAllClickC}
                        />
                        <TableBody>
                          {filteredDeptOutputs
                            .slice(pageC * rowsPerPage3, pageC * rowsPerPage3 + rowsPerPage3)
                            .map((row) => {
                              const { id, departmentLevel, classroom, departmentInput, day, time, lecturer } =
                                camelcaseKeys(row);
                              const isItemSelected = selectedC.indexOf(id) !== -1;

                              return (
                                <TableRow
                                  hover
                                  key={id}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox checked={isItemSelected} onChange={(event) => handleClickC(event, id)} />
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                      {/* <Avatar alt={name} src={avatarUrl} /> */}
                                      <Typography variant="subtitle2" noWrap>
                                        {!departmentLevel
                                          ? '-'
                                          : `${departmentLevel.level} ${departmentLevel.ft ? 'FT' : 'PT'} ${
                                              departmentLevel.course_option
                                            }`}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="20">
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                      <Typography variant="subtitle2" noWrap>
                                        {classroom !== null && classroom.venue}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Typography variant="subtitle2" noWrap>
                                      {departmentInput.course_code}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Typography variant="subtitle2" noWrap>
                                      {capitalize(day)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Typography variant="subtitle2" noWrap>
                                      {time}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Typography variant="subtitle2" noWrap>
                                      {lecturerCodes(lecturer, departmentInput.lecturer_id)}
                                    </Typography>
                                  </TableCell>

                                  <TableCell align="right">
                                    {/* <UserMoreMenu type="department input" identity={id} /> */}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows3 > 0 && (
                            <TableRow style={{ height: 53 * emptyRows3 }}>
                              <TableCell colSpan={12} />
                            </TableRow>
                          )}
                        </TableBody>

                        {isDeptOutputsNotFound && (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                                <SearchNotFound searchQuery={filterDeptOutput} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={deptOutputs.length}
                    rowsPerPage={rowsPerPage3}
                    page={pageC}
                    onPageChange={handleChangePageC}
                    onRowsPerPageChange={handleChangeRowsPerPage3}
                  />
                </>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
