import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import DownloadPdfLayout from './layouts/dashboard/DownloadPdf';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Collections from './pages/Collections';
import Lecturer from './pages/Lecturer';
import ViewLecturer from './pages/ViewLecturer';
import LecturerCsvImport from './pages/LecturerCsvImport';
import Department from './pages/Department';
import DeptCsvImport from './pages/DeptCsvImport';
import ViewDepartment from './pages/ViewDepartment';
import ViewDepartmentInput from './pages/ViewDepartmentInput';
import ViewDepartmentLevel from './pages/ViewDepartmentLevel';
import ViewClassroom from './pages/ViewClassroom';
import ClassroomCsvImport from './pages/ClassroomCsvImport';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Allocations from './pages/Allocations';
import Analytics from './pages/Analytics';
import SessionAllocation from './pages/SessionAllocation';
import SemesterAllocation from './pages/SemesterAllocation';
import AllocationView from './pages/ViewAllocation';
import ProductView from './pages/ViewProduct';
import Classroom from './pages/Classroom';
import CategoryView from './pages/ViewCategory';
import Orders from './pages/Orders';
import Reviews from './pages/Reviews';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import DashboardApp from './pages/DashboardApp';
import DepartmentOutputDownload from './pages/DepartmentOutputDownload';
import LecturerOutputDownload from './pages/LecturerOutputDownload';
import ClassroomOutputDownload from './pages/ClassroomOutputDownload';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'lecturers', element: <Lecturer /> },
        { path: 'lecturer/:id', element: <ViewLecturer /> },
        { path: 'lecturer/import/csv', element: <LecturerCsvImport /> },
        { path: 'departments', element: <Department /> },
        { path: 'department/:id', element: <ViewDepartment /> },
        { path: 'department/import/csv', element: <DeptCsvImport /> },
        { path: 'department/input/:id', element: <ViewDepartmentInput /> },
        { path: 'department/level/:id', element: <ViewDepartmentLevel /> },
        { path: 'classrooms', element: <Classroom /> },
        { path: 'classroom/:id', element: <ViewClassroom /> },
        { path: 'classroom/import/csv', element: <ClassroomCsvImport /> },
        { path: 'category/:id', element: <CategoryView /> },
        { path: 'allocations', element: <Allocations /> },
        { path: 'allocations/session', element: <SessionAllocation /> },
        { path: 'allocations/semester', element: <SemesterAllocation /> },
        { path: 'allocation/:id', element: <AllocationView /> },
        { path: 'product/:id', element: <ProductView /> },
        { path: 'analytics', element: <Analytics /> },
        { path: 'profile', element: <Profile /> },
        { path: 'settings', element: <Settings /> },
      ],
    },
    {
      path: '/download/department/:id/pdf',
      element: <DepartmentOutputDownload />,
    },
    {
      path: '/download/lecturer/:id/pdf',
      element: <LecturerOutputDownload />,
    },
    {
      path: '/download/classroom/:id/pdf',
      element: <ClassroomOutputDownload />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
