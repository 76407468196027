import { filter, capitalize } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import { ShimmerTable } from 'react-shimmer-effects';
import { useState, useEffect } from 'react';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  CardContent,
  ImageList,
  ImageListItem,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Drawer,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
  Link,
} from '@mui/material';

import axios from 'axios';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';

// utils
import { BASE_URL, categoryTypes } from '../utils/data';
import { view } from '../utils/auth';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ViewCategory() {
  const params = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [state, setState] = useState(false);

  const [loader, setLoader] = useState(true);

  const [msg, setMsg] = useState();
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);

  const [token, setToken] = useState(view()._token);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const handleImage = () => {};

  const handleUpdateCategory = async (e) => {
    e.preventDefault();
    try {
      let response = null;
      response = await axios.put(`${BASE_URL}admin/category/${params.id}/update`, category, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setState(false);
      setLoader(true);
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const deleteCategory = async () => {
    try {
      const response = await axios.delete(`${BASE_URL}admin/category/${params.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      navigate('/dashboard/categories', { replace: true });
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const deactivateCategory = async (status) => {
    try {
      const response = await axios.put(
        `${BASE_URL}admin/category/${params.id}/update`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setLoader(true);
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const activateCategory = async (status) => {
    try {
      const response = await axios.put(
        `${BASE_URL}admin/category/${params.id}/update`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setLoader(true);
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  useEffect(() => {
    async function fetchCategory() {
      if (loader) {
        const response = await axios.get(`${BASE_URL}admin/category/${params.id}/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        let catresponse = [];
        if (response.data.data.type === 'sub1') {
          catresponse = await axios.get(`${BASE_URL}admin/category/view?status=true&type=parent`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } else if (response.data.data.type === 'sub2') {
          catresponse = await axios.get(`${BASE_URL}admin/category/view?status=true&type=sub1`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        }

        setLoader(false);
        setCategory(response.data.data);
        setCategories(catresponse.data.data);
      }
    }

    fetchCategory();
  }, [category, categories, token, loader, params]);

  if (loader === true) {
    return (
      <div className="text-center p-5">
        <h3>Loading...</h3>
      </div>
    );
  }

  return (
    <Page title="View Product">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              {`${category.name}`}
            </Typography>
            {/* <Typography variant="body" gutterBottom>
              {`${product.category.name}`}
            </Typography> */}
          </div>
          {category.status === true ? (
            <Button
              variant="contained"
              onClick={() => deactivateCategory(0)}
              // startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Deactivate
            </Button>
          ) : (
            <div>
              <Button
                variant="contained"
                onClick={() => activateCategory(1)}
                // startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Activate
              </Button>
              &nbsp;
              <Button
                variant="contained"
                onClick={deleteCategory}
                color="error"
                // startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Delete
              </Button>
            </div>
          )}
        </Stack>

        <Stack mb={3}>{msg}</Stack>

        <Card>
          <CardContent>
            <Stack spacing={3}>
              <Typography variant="h4" component="h2">
                Update Category
              </Typography>

              <form onSubmit={handleUpdateCategory}>
                <Stack spacing={2}>
                  <TextField
                    label="Name"
                    id="name"
                    value={category.name}
                    onChange={(e) => setCategory({ ...category, name: e.target.value })}
                    required
                  />

                  <TextField
                    fullWidth
                    label="Description"
                    id="description"
                    multiline
                    rows={4}
                    value={category.description}
                    onChange={(e) => setCategory({ ...category, description: e.target.value })}
                    required
                  />

                  {category.type !== 'parent' && (
                    <FormControl fullWidth>
                      <InputLabel id="type-label">Category</InputLabel>
                      <Select
                        labelId="type-label"
                        id="type-label"
                        value={category.parent_cat_id === null ? '' : category.parent_cat_id}
                        label="Category"
                        onChange={(e) => setCategory({ ...category, parent_cat_id: e.target.value })}
                        defaultValue={category.parent_cat_id === null ? '' : category.parent_cat_id}
                      >
                        <MenuItem value={null}>None</MenuItem>

                        {categories.length > 0 &&
                          categories.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                              {category.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}

                  <Button variant="outlined" component="label" size="large">
                    Upload File
                    <input type="file" hidden onChange={handleImage} />
                  </Button>

                  <ImageList sx={{ width: 500, height: 164 }} cols={3} rowHeight={164}>
                    <ImageListItem key={category.image}>
                      <img
                        src={`${category.image}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${category.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt="catimage"
                        loading="lazy"
                      />
                    </ImageListItem>
                  </ImageList>
                </Stack>

                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={3}>
                  <Button variant="contained" type="submit">
                    Update
                  </Button>
                </Stack>
              </form>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
