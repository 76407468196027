import { filter, capitalize } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import { ShimmerTable } from 'react-shimmer-effects';
import { useState, useEffect } from 'react';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  CardContent,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Drawer,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
  Link,
} from '@mui/material';

import axios from 'axios';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';

// utils
import { BASE_URL, session } from '../utils/data';
import { view } from '../utils/auth';
import { viewLecturerAllocations } from '../utils/timetable';

// ----------------------------------------------------------------------

const TABLE_HEAD_OUTPUT = [
  { id: 'dept', label: 'Department', alignRight: false },
  { id: 'level', label: 'Level', alignRight: false },
  { id: 'class', label: 'Classroom', alignRight: false },
  { id: 'course_code', label: 'Course Code', alignRight: false },
  { id: 'day', label: 'Day', alignRight: false },
  { id: 'time', label: 'Time', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applyLecturerOutputSortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(
      array,
      (_d) =>
        _d.department_level.dept_code.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.department_level.level.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.department_input.course_code.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.classroom.venue.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.time.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _d.day.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ViewLecturer() {
  const params = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [state, setState] = useState(false);

  const [loader, setLoader] = useState(true);

  const [showPassword, setShowPassword] = useState(false);

  const [newUser, setNewUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    type: '',
    password: 'password123',
  });

  const [filterLecturerOutput, setFilterLecturerOutput] = useState('');

  const [msg, setMsg] = useState();
  const [lecturer, setlecturer] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [lecturerOutputs, setLecturerOutputs] = useState([]);

  const [token, setToken] = useState(view()._token);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = lecturerOutputs.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const handleFilterByLecturerOutput = (event) => {
    setFilterLecturerOutput(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - lecturerOutputs.length) : 0;

  const filteredLecturerOutputs = applyLecturerOutputSortFilter(
    lecturerOutputs,
    getComparator(order, orderBy),
    filterLecturerOutput
  );

  const isLecturerOutputNotFound = filteredLecturerOutputs.length === 0;

  const deleteLecturer = async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}admin/lecturer/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setLoader(true);
      navigate('/dashboard/lecturers');
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const handleUpdateLecturer = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${BASE_URL}admin/lecturer/${params.id}/update`, lecturer, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      navigate('/dashboard/lecturers');
      setLoader(true);
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  useEffect(() => {
    async function fetchLecturer() {
      if (loader) {
        const response = await axios.get(`${BASE_URL}admin/department/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setDepartments(response.data.data);

        const response2 = await axios.get(`${BASE_URL}admin/lecturer/${params.id}/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const response3 = await axios.get(`${BASE_URL}admin/allocation/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setLoader(false);
        setlecturer(response2.data.data);
        setLecturerOutputs(
          response3.data.data.filter(
            (data) =>
              viewLecturerAllocations(params.id, data.lecturer_id) === 1 && data.department_level.session === session
          )
        );
      }
    }

    fetchLecturer();
  }, [lecturer, lecturerOutputs, departments, token, loader, params]);

  if (loader === true) {
    return (
      <div className="text-center p-5">
        <h3>Loading...</h3>
      </div>
    );
  }

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {`${lecturer.name}`}
          </Typography>
          <div>
            <Button
              variant="contained"
              onClick={() => deleteLecturer(lecturer.id)}
              color="error"
              // startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Delete
            </Button>
          </div>
        </Stack>

        <Stack mb={3}>{msg}</Stack>

        <Card>
          <CardContent>
            <Stack spacing={3}>
              <Typography variant="h4" component="h2">
                Update Lecturer
              </Typography>

              <form onSubmit={handleUpdateLecturer}>
                <Stack spacing={2}>
                  <TextField
                    label="Code"
                    id="id"
                    value={lecturer.id}
                    onChange={(e) => setlecturer({ ...lecturer, id: e.target.value })}
                    required
                    disabled
                  />

                  <TextField
                    label="Name"
                    id="name"
                    value={lecturer.name}
                    onChange={(e) => setlecturer({ ...lecturer, name: e.target.value })}
                    required
                  />

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Department</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={lecturer.dept_code}
                      label="Department"
                      onChange={(e) => setlecturer({ ...lecturer, dept_code: e.target.value })}
                    >
                      {departments.map((dept) => (
                        <MenuItem key={dept.id} value={dept.id}>
                          {dept.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="type-label">Type</InputLabel>
                    <Select
                      labelId="type-label"
                      id="type-label"
                      value={lecturer.type}
                      label="Type"
                      onChange={(e) => setlecturer({ ...lecturer, type: e.target.value })}
                      required
                    >
                      <MenuItem value={'technologist'}>Technologist</MenuItem>
                      <MenuItem value={'lecturer'}>Lecturer</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>

                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={3}>
                  <Button variant="contained" type="submit">
                    Update
                  </Button>
                </Stack>
              </form>
            </Stack>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" component="h2">
                  Lecturer's Codes
                </Typography>
                <div>
                  <Button
                    variant="contained"
                    to={`/download/lecturer/${lecturer.id}/pdf`}
                    component={RouterLink}
                    // onClick={toggleDrawerB('right', true)}
                    startIcon={<Iconify icon="eva:download-fill" />}
                  >
                    Download
                  </Button>
                </div>
              </Stack>

              {loader === true ? (
                <ShimmerTable row={5} col={5} />
              ) : (
                <>
                  <UserListToolbar
                    numSelected={selected.length}
                    filterName={filterLecturerOutput}
                    onFilterName={handleFilterByLecturerOutput}
                  />

                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <UserListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD_OUTPUT}
                          rowCount={lecturerOutputs.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {filteredLecturerOutputs
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                              const { id, departmentLevel, classroom, day, time, departmentInput } = camelcaseKeys(row);
                              const isItemSelected = selected.indexOf(id) !== -1;

                              return (
                                <TableRow
                                  hover
                                  key={id}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                      <Typography variant="subtitle2" noWrap>
                                        {departmentLevel.dept_code}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                      <Typography variant="subtitle2" noWrap>
                                        {departmentLevel.level} {departmentLevel.ft ? 'FT' : 'PT'}{' '}
                                        {departmentLevel.course_option}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="20">
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                      <Typography variant="subtitle2" noWrap>
                                        {classroom !== null && classroom.venue}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Typography variant="subtitle2" noWrap>
                                      {departmentInput.course_code}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Typography variant="subtitle2" noWrap>
                                      {capitalize(day)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="10">
                                    <Typography variant="subtitle2" noWrap>
                                      {time}
                                    </Typography>
                                  </TableCell>

                                  <TableCell align="right">
                                    {/* <UserMoreMenu type="department input" identity={id} /> */}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={12} />
                            </TableRow>
                          )}
                        </TableBody>

                        {isLecturerOutputNotFound && (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                                <SearchNotFound searchQuery={filterLecturerOutput} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={lecturerOutputs.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
