// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'departments',
    path: '/dashboard/departments',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'lecturers',
    path: '/dashboard/lecturers',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'classrooms',
    path: '/dashboard/classrooms',
    icon: getIcon('eva:home-fill'),
  },
  {
    title: 'allocations',
    path: '/dashboard/allocations',
    icon: getIcon('eva:list-fill'),
  },
  {
    title: 'analytics',
    path: '/dashboard/analytics',
    icon: getIcon('eva:bar-chart-fill'),
  },
];

export default navConfig;
