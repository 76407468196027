import { filter, capitalize } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import { ShimmerTable } from 'react-shimmer-effects';
import { useState, useEffect } from 'react';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  CardContent,
  ImageList,
  ImageListItem,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Drawer,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
  Link,
} from '@mui/material';

import axios from 'axios';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';

// utils
import { BASE_URL } from '../utils/data';
import { view } from '../utils/auth';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ViewProduct() {
  const params = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [state, setState] = useState(false);

  const [loader, setLoader] = useState(true);

  const [msg, setMsg] = useState();
  const [product, setProduct] = useState(null);
  const [categories, setCategories] = useState([]);

  const [token, setToken] = useState(view()._token);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const handleImage = () => {};

  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    try {
      let response = null;
      response = await axios.put(`${BASE_URL}admin/item/${params.id}/update`, product, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setState(false);
      setLoader(true);
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const deleteProduct = async () => {
    try {
      const response = await axios.delete(`${BASE_URL}admin/item/${params.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      navigate('/dashboard/products', { replace: true });
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const deactivateProduct = async (status) => {
    try {
      const response = await axios.put(
        `${BASE_URL}admin/item/${params.id}/update`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setLoader(true);
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const activateProduct = async (status) => {
    try {
      const response = await axios.put(
        `${BASE_URL}admin/item/${params.id}/update`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setLoader(true);
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  useEffect(() => {
    async function fetchItem() {
      if (loader) {
        const response = await axios.get(`${BASE_URL}admin/item/${params.id}/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const catresponse = await axios.get(
          `${BASE_URL}admin/category/view?status=true&type=${response.data.data.category.type}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setLoader(false);
        setProduct(response.data.data);
        setCategories(catresponse.data.data);
      }
    }

    fetchItem();
  }, [product, categories, token, loader, params]);

  if (loader === true) {
    return (
      <div className="text-center p-5">
        <h3>Loading...</h3>
      </div>
    );
  }

  return (
    <Page title="View Product">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              {`${product.name}`}
            </Typography>
            <Typography variant="body" gutterBottom>
              {`${product.category !== null && product.category.name}`}
            </Typography>
          </div>
          {product.status === true ? (
            <Button
              variant="contained"
              onClick={() => deactivateProduct(0)}
              // startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Deactivate
            </Button>
          ) : (
            <div>
              <Button
                variant="contained"
                onClick={() => activateProduct(1)}
                // startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Activate
              </Button>
              &nbsp;
              <Button
                variant="contained"
                onClick={deleteProduct}
                color="error"
                // startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Delete
              </Button>
            </div>
          )}
        </Stack>

        <Stack mb={3}>{msg}</Stack>

        <Card>
          {loader === true ? (
            <ShimmerTable row={5} col={5} />
          ) : (
            <CardContent>
              <Stack spacing={3}>
                <Typography variant="h4" component="h2">
                  Update Product Item
                </Typography>

                <form onSubmit={handleUpdateProduct}>
                  <Stack spacing={2}>
                    <TextField
                      label="Name"
                      id="name"
                      value={product.name}
                      onChange={(e) => setProduct({ ...product, name: e.target.value })}
                      required
                    />
                    <TextField
                      label="Price"
                      id="price"
                      value={product.price}
                      onChange={(e) => setProduct({ ...product, price: e.target.value })}
                      required
                    />
                    <TextField
                      multiline
                      label="Description"
                      id="description"
                      rows={4}
                      value={product.description}
                      onChange={(e) => setProduct({ ...product, description: e.target.value })}
                      required
                    />

                    <FormControl>
                      <InputLabel id="type-label">Type of item</InputLabel>
                      <Select
                        labelId="type-label"
                        id="type-label"
                        value={product.type}
                        label="Type of item"
                        onChange={(e) => setProduct({ ...product, type: e.target.value })}
                        required
                      >
                        <MenuItem value={'food'}>Food</MenuItem>
                        <MenuItem value={'addition'}>Addition</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <InputLabel id="category-label">Category</InputLabel>
                      <Select
                        labelId="category-label"
                        id="category-label"
                        value={product.cat_id}
                        label="Category"
                        onChange={(e) => setProduct({ ...product, cat_id: e.target.value })}
                        required
                        defaultValue={product.cat_id}
                      >
                        {categories.map((category) => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Button variant="outlined" component="label" size="large">
                      Upload File
                      <input type="file" hidden onChange={handleImage} />
                    </Button>

                    <ImageList sx={{ width: 500, height: 164 }} cols={3} rowHeight={164}>
                      <ImageListItem key={product.image1}>
                        <img
                          src={`${product.image1}?w=164&h=164&fit=crop&auto=format`}
                          srcSet={`${product.image1}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          alt="image1"
                          loading="lazy"
                        />
                      </ImageListItem>
                    </ImageList>
                  </Stack>

                  <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={3}>
                    <Button variant="contained" type="submit">
                      Update
                    </Button>
                  </Stack>
                </form>
              </Stack>
            </CardContent>
          )}
        </Card>
      </Container>
    </Page>
  );
}
