const { days, times, times_, semesters, deptLevels } = require('./data');

/**
 * overall verification
 * @param {day, from, to, ...dept_input} credential
 */

export const session = () => {
  const year = new Date().getFullYear();
  const prevYear = year - 1;
  const session = `${prevYear}/${year}`;
  return session;
};

export const semester = (_semester) => {
  let semester_ = '';
  semesters.forEach((semester) => {
    if (semester[0] === _semester) {
      semester_ = semester[1];
    }
  });
  return semester_;
};

export const deptLevelOutput = (deptAllocation) => {
  let deptLevelMode = '';
  if (deptAllocation.department_level !== null) {
    deptLevelMode = `${deptAllocation.department_level.level} ${deptAllocation.department_level.ft ? 'FT' : 'PT'} ${
      deptAllocation.department_level.course_option
    }`;
  }
  // console.log(deptLevelMode);
  return deptLevelMode;
};

export const deptLevelAllocation = (deptAllocations) => {
  const allocation = [deptAllocations[0]];
  return allocation;
};

export const dayAllocation = (day, deptAllocations) => {
  const allocationForDay = deptAllocations.filter((deptAllocation) => deptAllocation.day === day);
  return allocationForDay;
};

export const verify = (credential) => {
  const verifyTimesResult = verifyTimes(credential.lecture_hrs, credential.from, credential.to);
  let overallResponse = { error: 0 };
  // console.log(verifyTimesResult);
  if (verifyTimesResult.error === 1) {
    overallResponse = verifyTimesResult;
  }
  return overallResponse;
};

/**
 * verify Time for lecture
 * @param {*} duration
 * @param {*} from
 * @param {*} to
 */
export const verifyTimes = (lectureHrs, from, to) => {
  const timeResult = to - from;
  let response = null;
  console.log(from);
  console.log(lectureHrs);
  if (from > to) {
    response = { error: 1, msg: 'The lecture hours were wrongly selected!' };
  } else if (timeResult !== lectureHrs) {
    response = { error: 1, msg: 'The hour for lecture has been violated!' };
  } else {
    response = { error: 0 };
  }
  return response;
};

export const getLecture = (day, time, departmentAllocations, deptLevel) => {
  const allocations = departmentAllocations.filter(
    (allocation) => allocation.day === day && deptLevel === deptLevelOutput(allocation)
  );
  console.log(allocations);
  let cellCount = 0;
  let allocationDetails = null;
  for (let index = 0; index < allocations.length; index += 1) {
    const allocation = allocations[index];
    const isTimeFound = allocation.time.split('-').includes(time);

    if (isTimeFound && allocation.time.split('-').indexOf(time) === 0) {
      const time_ = allocation.time;
      const timeArr = time_.split('-');
      const startTime = getTime(0, timeArr[0]);
      const endTime = getTime(0, timeArr[1]);

      const hr = endTime[1] - startTime[1];
      cellCount += hr;
      allocationDetails = allocation;
    } else {
      break;
    }
  }
  return {
    cellCount,
    allocation: allocationDetails,
  };
};

export const getTime = (index, selectedTime) => {
  const timeArr = times.filter((time) => time[index] === selectedTime);
  return timeArr[0];
};

export const getTimeFormat = (startTimeArr, stopTimeArr) => {
  let startTime = startTimeArr[1];
  let stopTime = stopTimeArr[1];
  if (startTime > 12) {
    startTime -= 12;
  }

  if (stopTime > 12) {
    stopTime -= 12;
  }

  const result = `${startTime}${startTimeArr[2]}-${stopTime}${stopTimeArr[2]}`;
  return result;
};

export const getTimeDiff = (startTimeArr, stopTimeArr) => {
  let startTime = startTimeArr[1];
  let stopTime = stopTimeArr[1];
  if (startTime > 12) {
    startTime -= 12;
  }

  if (stopTime > 12) {
    stopTime -= 12;
  }

  const result = stopTime - startTime;
  return result;
};

// const lectureDayAndTime = (deptLevel, allocations) => {
//   const lectureAllocations = [];
//   const deptLevelAllocations = allocations.filter((allocation) => deptLevel === deptLevelOutput(allocation));

//   for (let i = 0; i < days.length; i += 1) {
//     const day = days[i];
//     // lectureAllocations.push([{day}]);
//     for (let j = 0; j < times_.length; j += 1) {
//       const time = times_[j];

//       const daysAllocations = deptLevelAllocations.filter((deptLevelAllocation) => deptLevelAllocation.day === day);
//       const timeArr = time[1].split('-');
//       // const dayAllocationIndex = lectureAllocations.indexOf()
//       if(daysAllocations.filter(dayAllocation => timeArr.indexOf(dayAllocation.time) === 0).length > 0){
//         lectureAllocations.push([
//           {day},
//         ])
//       }
//     }
//   }

//   return lectureAllocations;
// };

export const getTable = (_allocations) => {
  const weeks = {};

  days.forEach((day) => {
    const result = _allocations.filter((_allocation) => _allocation.day === day);

    const grouped = result.reduce((map, obj) => map.set(obj.time, (map.get(obj.time) || []).concat(obj)), new Map());
    // console.log(grouped.get('8am-9am'));
    const _result = times_.flatMap((time) => grouped.get(time) || [{}]);

    weeks[day] = _result;

    // console.log(weeks);
  });

  // console.log(weeks);

  return weeks;
};

// export const isArrayOfEmptyObjs = (_arr) => {
//   _arr.forEach((obj) => {
//     Object.keys(obj).length > 0 ? false : true;
//   });

//   return true;
// };

export const lectureDayAndTime = (allocations) => {
  const lectures = [];
  for (let i = 0; i < days.length; i += 1) {
    const day = days[i];
    for (let j = 0; j < times_.length; j += 1) {
      const time = times_[j];
      const filteredAllocation = allocations.filter((allocation) => allocation.day === day && allocation.time === time);
      if (filteredAllocation.length > 1) {
        // let lectureDetails = {...filteredAllocation[0]};
        let classes = [];
        for (let k = 0; k < filteredAllocation.length; k += 1) {
          const allocation2 = filteredAllocation[k];
          // classes.push({dept_code: allocation2.department_level.dept_code, dept_level_id: allocation2.dept_level_id, dept_input_id: allocation2.dept_input_id})
          classes.push(
            formatTimeTableLevel(
              allocation2.department_level.dept_code,
              allocation2.department_level.level,
              allocation2.department_level.ft
            )
          );
        }
        classes = classes.join('/');
        const lectureDetails = { ...filteredAllocation[0], classes };
        lectures.push(lectureDetails);
      } else if (filteredAllocation.length === 1) {
        const lectureDetails = { ...filteredAllocation[0], classes: null };
        lectures.push(lectureDetails);
      }
    }
  }
  return lectures;
};

export function formatTimeTableLevel(dept, level, mode) {
  // CS, HND/ND II, true/false
  // -> HCS II or NCS II
  if (level.substring(0, 1) === 'H' && !mode) return "HND can't have PT";

  return mode
    ? `${level.substring(0, 1)}${dept} ${level.split(' ')[1]}`
    : `P${level.substring(0, 1)}${dept} ${level.split(' ')[1]}`;
}

export const lecturerCodes = (lecturer, lecturerId) => {
  if (!lecturer) {
    return '-';
  }

  if (lecturerId.indexOf(',') !== -1) {
    return lecturerId;
  }

  return `${lecturer.name} (${lecturer.id})`;
};

export const lecturerCodesForOutput = (lecturer, lecturerId) => {
  if (!lecturer) {
    return '-';
  }

  if (lecturerId.indexOf(',') !== -1) {
    return lecturerId.split(',').join('/');
  }

  return lecturerId;
};

export const viewLecturerAllocations = (paramLecturerId, lecturerId) => {
  if (lecturerId.indexOf(',') !== -1) {
    const _lecturers = lecturerId.split(',');
    const lecturer = _lecturers.filter((l) => paramLecturerId === l);

    return lecturer.length > 0 ? 1 : 0;
  }
  return paramLecturerId === lecturerId ? 1 : 0;
};

/**
 * Divide array
 * @param {*} inputArray
 * @param {*} perChunk
 * @returns
 */
export const divideArray = (inputArray, perChunk) => {
  const result = inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return result;
};

const remainingAllocationForClassroom = (classroom, _allocations) => {
  const allocations_ = _allocations.filter((allocation) => allocation.classroom_id === classroom.id);
  let totalHrs = 0;
  const NotGeneralAllocations_ = allocations_.filter((allocation) => allocation.department_input.type !== 'general');
  const GeneralAllocations_ = allocations_.filter((allocation) => allocation.department_input.type === 'general');

  console.log(classroom);
  if (NotGeneralAllocations_.length > 0) {
    for (let i = 0; i < NotGeneralAllocations_.length; i += 1) {
      const allocation_ = NotGeneralAllocations_[i];

      const startTimeArr = getTime(0, allocation_.time.split('-')[0]);
      const stopTimeArr = getTime(0, allocation_.time.split('-')[1]);
      totalHrs += getTimeDiff(startTimeArr, stopTimeArr);
    }
  }

  const DistinctGeneralAllocation = [];
  if (GeneralAllocations_.length > 0) {
    for (let i = 0; i < GeneralAllocations_.length; i += 1) {
      const allocation_ = GeneralAllocations_[i];
      if (DistinctGeneralAllocation.length === 0) {
        DistinctGeneralAllocation.push(allocation_);
      } else {
        const dGeneralAllLength = DistinctGeneralAllocation.filter(
          (dAllocation) =>
            dAllocation.lecturer_id === allocation_.lecturer_id &&
            dAllocation.department_input.course_code === allocation_.department_input.course_code &&
            dAllocation.time === allocation_.time &&
            dAllocation.day === allocation_.day
        );
        if (dGeneralAllLength.length === 0) {
          DistinctGeneralAllocation.push(allocation_);
        }
      }
    }
  }

  if (DistinctGeneralAllocation.length > 0) {
    for (let i = 0; i < DistinctGeneralAllocation.length; i += 1) {
      const allocation_ = DistinctGeneralAllocation[i];

      const startTimeArr = getTime(0, allocation_.time.split('-')[0]);
      const stopTimeArr = getTime(0, allocation_.time.split('-')[1]);
      totalHrs += getTimeDiff(startTimeArr, stopTimeArr);
    }
  }

  return classroom.hours - totalHrs;
};

/**
 * estimated hrs
 * @param {*} _classroomChunks
 * @param {*} _allocations
 * @returns
 */
export const estimatedHrsLeft = (_classroomChunks, _allocations) => {
  const data = _classroomChunks.map((classroom) => {
    const _estimatedHrsLeft = remainingAllocationForClassroom(classroom, _allocations);
    const _data = { name: classroom.venue, 'Remaining Hrs': _estimatedHrsLeft };
    return _data;
  });
  return data;
};
