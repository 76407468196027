// import { session as Session } from './timetable';

// export const BASE_URL = 'http://localhost:4007/';
export const BASE_URL = 'https://api.tmsfpi.com/core/';
export const session = localStorage._session || '2022/2023';
export const categoryTypes = ['parent', 'sub1', 'sub2'];
export const itemTypes = ['food', 'addition'];
export const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export const days_ = [
  ['mon', 'monday'],
  ['tue', 'tuesday'],
  ['wed', 'wednesday'],
  ['thur', 'thursday'],
  ['fri', 'friday'],
  ['sat', 'saturday'],
  ['sun', 'sunday'],
];

export const times = [
  ['8am', 8, 8],
  ['9am', 9, 9],
  ['10am', 10, 10],
  ['11am', 11, 11],
  ['12pm', 12, 12],
  ['1pm', 13, 1],
  ['2pm', 14, 2],
  ['3pm', 15, 3],
  ['4pm', 16, 4],
  ['5pm', 17, 5],
  ['6pm', 18, 6],
  ['7pm', 19, 7],
  ['8pm', 20, 8],
];

export const times_ = [
  '8am-9am',
  '9am-10am',
  '10am-11am',
  '11am-12pm',
  '12pm-1pm',
  '1pm-2pm',
  '2pm-3pm',
  '3pm-4pm',
  '4pm-5pm',
  '5pm-6pm',
  '6pm-7pm',
  '7pm-8pm',
];

export const semesters = [
  ['1st', 'First Semester'],
  ['2nd', 'Second Semester'],
  ['3rd', 'Third Semester'],
];

export const deptLevels = ['ND I FT', 'ND I PT', 'ND II FT', 'ND II PT', 'HND I FT', 'HND II FT'];
