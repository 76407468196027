import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Stack, TextField, Button, Card, CardContent } from '@mui/material';

import axios from 'axios';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

import { BASE_URL, session } from '../utils/data';
import { view } from '../utils/auth';
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();

  const [depts, setDepts] = useState([]);
  const [lecturers, setLecturers] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [allocations, setAllocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [msg, setMsg] = useState('');
  const [token, setToken] = useState(view()._token);

  const [_session, _setSession] = useState(session);
  const handleSession = (e) => {
    e.preventDefault();
    localStorage.setItem('_session', _session);
    window.location = window.location.origin + window.location.pathname;
  };

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(`${BASE_URL}admin/department/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response2 = await axios.get(`${BASE_URL}admin/lecturer/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response3 = await axios.get(`${BASE_URL}admin/classroom`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response4 = await axios.get(`${BASE_URL}admin/allocation/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDepts(response.data.data);
      setLecturers(response2.data.data);
      setClassrooms(response3.data.data);
      setAllocations(response4.data.data);
      if (localStorage._session) {
        _setSession(localStorage.getItem('_session'));
      }
      setIsLoading(false);
    }
    if (isLoading) {
      fetchData();
    }
  }, [isLoading, token, depts, lecturers, classrooms, _session, allocations]);

  if (isLoading) {
    return (
      <div className="text-center p-5">
        <h3>Loading...</h3>
      </div>
    );
  }
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Departments" total={depts.length} icon={'ant-design:dashboard-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Lecturers"
              total={lecturers.length}
              color="info"
              icon={'ant-design:usergroup-add-outlined'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Classrooms"
              total={classrooms.length}
              color="warning"
              icon={'ant-design:home-outlined'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} mb={5}>
            <AppWidgetSummary
              title="Allocations"
              total={allocations.length}
              color="error"
              icon={'ant-design:bars-outlined'}
            />
          </Grid>

          <Container>
            <Card>
              <CardContent>
                <Stack spacing={3}>
                  <Typography variant="h4" component="h2">
                    Department Inputs
                  </Typography>
                  <form onSubmit={handleSession}>
                    <Stack spacing={2}>
                      <TextField
                        fullWidth
                        label="Session"
                        id="session"
                        value={_session}
                        onChange={(e) => _setSession(e.target.value)}
                        required
                      />
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={3}>
                      <Button variant="contained" type="submit">
                        Create
                      </Button>
                    </Stack>
                  </form>
                </Stack>
              </CardContent>
            </Card>
          </Container>

          {/* <Grid item xs={12} md={6} lg={12}>
            <AppNewsUpdate
              title="Allocations Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
