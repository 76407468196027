const typesReducer = (state, action) => {
  switch (action.type) {
    case 'parent':
      return { type: action.type, loader: action.loader, status: action.status };
    case 'sub1':
      return { type: action.type, loader: action.loader, status: action.status };
    case 'sub2':
      return { type: action.type, loader: action.loader, status: action.status };
    default:
      return { type: 'parent', loader: true, status: true };
  }
};

export default typesReducer;
