import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import '../layouts/dashboard/pdf.css';

// import DeptLevelOutputs from './DeptLevelOutputs';
import { BASE_URL, deptLevels, days_, times, times_, session } from '../utils/data';
import { view } from '../utils/auth';
import { semester, deptLevelOutput, deptLevelAllocation, getTable, lecturerCodesForOutput } from '../utils/timetable';

// import  from 'react';

export default function DepartmentOutputDownload() {
  const params = useParams();
  const [deptAllocations, setDeptAllocations] = useState([]);
  const [dept, setDept] = useState(null);
  const [token, setToken] = useState(view()._token);
  const [isLoading, setIsLoading] = useState(true);

  const [loadedDeptLevels, setLoadedDeptLevels] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (isLoading) {
        const response = await axios.get(`${BASE_URL}admin/allocation/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // const dept = response.data.data[0].department_level.dept_code;
        const response2 = await axios.get(`${BASE_URL}admin/department/${params.id}/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const dA = response.data.data.filter((data) => data.department_input.dept_code === params.id);
        setDeptAllocations(dA);

        console.log(dA);

        const availableDeptLevels = [];
        for (let i = 0; i < dA.length; i += 1) {
          const allocation = dA[i];
          if (allocation.department_level !== null) {
            if (availableDeptLevels.length === 0) {
              availableDeptLevels.push(
                allocation.department_level !== null &&
                  `${allocation.department_level.level} ${allocation.department_level.ft ? 'FT' : 'PT'} ${
                    allocation.department_level.course_option
                  }`
              );
            } else {
              const deptLevelDetails_ = availableDeptLevels.filter(
                (d) =>
                  d ===
                  `${allocation.department_level.level} ${allocation.department_level.ft ? 'FT' : 'PT'} ${
                    allocation.department_level.course_option
                  }`
              );
              if (deptLevelDetails_.length === 0) {
                availableDeptLevels.push(
                  `${allocation.department_level.level} ${allocation.department_level.ft ? 'FT' : 'PT'} ${
                    allocation.department_level.course_option
                  }`
                );
              }
            }
          }
        }
        setLoadedDeptLevels(availableDeptLevels);
        // console.log(availableDeptLevels);

        setDept(response2.data.data);
        setIsLoading(false);
        // console.log(
        //   response.data.data.filter(
        //     (data) => data.department_level.dept_code === params.id && data.department_level.session === '2021/2022'
        //   )
        // );
        // console.log(response2.data.data);
      }
    }
    fetchData();
  }, [deptAllocations, loadedDeptLevels, dept, isLoading, token, params]);

  console.log(deptAllocations);
  if (isLoading) {
    return <>Loading</>;
  }
  return (
    <>
      <div id="print" className="mt-20">
        <h3 className="text-center calibri">LECTURE TIME TABLE</h3>
        <DeptLevelsView deptLevels={loadedDeptLevels} deptAllocations={deptAllocations} dept={dept} />
        <div
          className="calibri"
          style={{ marginTop: '1em', marginBottom: '-10em', textAlign: 'center', color: 'grey' }}
        >
          Powered by: <br /> <b>FPI Timetable Committee</b>
        </div>
      </div>
    </>
  );
}

function DeptLevelsView({ deptLevels, deptAllocations, dept }) {
  return deptLevels.map((deptLevel) => (
    <div key={deptLevel}>
      <DeptLevelOutputs deptLevel={deptLevel} deptAllocations={deptAllocations} dept={dept} />
    </div>
  ));
}

function DeptLevelOutputs({ deptLevel, deptAllocations, dept }) {
  // console.log(deptLevelAllocation(deptAllocations));

  const [deptLevelDetails, setDeptLevelDetails] = useState(
    deptAllocations.filter((allocation) => deptLevel === deptLevelOutput(allocation))
  );
  const [allocations, setAllocations] = useState(
    getTable(deptAllocations.filter((allocation) => deptLevel === deptLevelOutput(allocation)))
  );
  const [loading, setLoading] = useState(true);

  return (
    <>
      {deptAllocations.filter((allocation) => deptLevel === deptLevelOutput(allocation)).length > 0 ? (
        <>
          <div className="timetable">
            <div className="timetable-level">
              <div className="mt-20">
                <div className="row">
                  <div className="col-one-third calibri">DEPARTMENT : {dept.name.toUpperCase()}</div>
                  <div className="col-one-third calibri">
                    {semester(deptLevelDetails[0].department_level.semester).toUpperCase()}
                  </div>
                  <div className="col-one-third calibri">
                    {deptLevelDetails[0].department_level.session} ACADEMIC SESSION
                  </div>
                </div>
              </div>

              <div className="my-20 font-bold calibri">
                {deptLevelDetails[0].department_level.ft ? 'FULL TIME' : 'PART TIME'}{' '}
                {deptLevelDetails[0].department_level.level} {deptLevelDetails[0].department_level.course_option}
              </div>

              <table className="table calibri">
                <tr>
                  <td className="tdata">{''}</td>
                  <td className="tdata">8 - 9</td>
                  <td className="tdata">9 - 10</td>
                  <td className="tdata">10 - 11</td>
                  <td className="tdata">11 - 12</td>
                  <td className="tdata">12 - 1</td>
                  <td className="tdata">1 - 2</td>
                  <td className="tdata">2 - 3</td>
                  <td className="tdata">3 - 4</td>
                  <td className="tdata">4 - 5</td>
                  <td className="tdata">5 - 6</td>
                  <td className="tdata">6 - 7</td>
                  <td className="tdata">7 - 8</td>
                </tr>
                {days_.map((day) => (
                  <tr key={day[0]}>
                    {/* {deptAllocations.map((deptAllocation) =>
                        deptAllocation.day === day[1] ? ( */}
                    <>
                      <td className="tdataWeek font-bold calibri">{day[0].toUpperCase()}</td>

                      {/* <td>{allocations.monday[0].department_input.course_code}</td> */}
                      {allocations[day[1]].map((allocation) =>
                        Object.keys(allocation).length > 0 ? (
                          <td key={allocation.id} className="tdata calibri">
                            <div className="venueAndLecturerCode calibri">
                              <span className="calibri">
                                {allocation.classroom !== null && allocation.classroom.venue}
                              </span>{' '}
                              <span className="lecturerCode calibri">
                                {lecturerCodesForOutput(allocation.lecturer, allocation.lecturer_id)}
                              </span>
                            </div>
                            <div className="courseCode calibri">{allocation.department_input.course_code}</div>
                          </td>
                        ) : (
                          <td className="tdata">{''}</td>
                        )
                      )}
                      {/* <td colSpan="2" className="tdata">
                          <div className="venueAndLecturerCode">
                            <span>AG3&4</span> <span className="lecturerCode">408</span>
                          </div>
                          <div className="courseCode">ICT 104</div>
                        </td>
                        <td className="tdata">{''}</td>
                        <td className="tdata">{''}</td>
                        <td colSpan="2" className="tdata">
                          <div className="venueAndLecturerCode">
                            <span>AG3&4</span> <span className="lecturerCode">408</span>
                          </div>
                          <div className="courseCode">ICT 104</div>
                        </td>
                        <td className="tdata">{''}</td>
                        <td className="tdata">{''}</td>
                        <td className="tdata">{''}</td>
                        <td colSpan="2" className="tdata">
                          <div className="venueAndLecturerCode">
                            <span>AG3&4</span> <span className="lecturerCode">408</span>
                          </div>
                          <div className="courseCode">ICT 104</div>
                        </td>
                        <td className="tdata">{''}</td> */}
                    </>
                    {/* ) : null
                      )} */}
                  </tr>
                ))}
              </table>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
        </>
      ) : null}
    </>
  );
}

/**
 * Get lecture
 * @param {*} day
 * @param {*} time
 * @param {*} departmentAllocations
 * @returns
 */
const getLecture = (day, time, departmentAllocations, deptLevel) => {
  const allocations = departmentAllocations.filter(
    (allocation) => allocation.day === day && deptLevel === deptLevelOutput(allocation)
  );
  console.log(allocations);
  let cellCount = 0;
  let allocationDetails = null;
  for (let index = 0; index < allocations.length; index += 1) {
    const allocation = allocations[index];
    const isTimeFound = allocation.time.split('-').includes(time);

    if (isTimeFound && allocation.time.split('-').indexOf(time) === 0) {
      const time_ = allocation.time;
      const timeArr = time_.split('-');
      const startTime = getTime(0, timeArr[0]);
      const endTime = getTime(0, timeArr[1]);

      const hr = endTime[1] - startTime[1];
      cellCount = hr;
      allocationDetails = allocation;
    }
  }
  return {
    cellCount,
    allocation: allocationDetails,
  };
};

const getTime = (index, selectedTime) => {
  const timeArr = times.filter((time) => time[index] === selectedTime);
  return timeArr[0];
};
