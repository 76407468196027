import { filter, capitalize } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import { ShimmerTable } from 'react-shimmer-effects';
import { useState, useEffect } from 'react';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  CardContent,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Drawer,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
  Link,
} from '@mui/material';

import axios from 'axios';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';

// utils
import { BASE_URL } from '../utils/data';
import { view } from '../utils/auth';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email Address', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

const classTypes = [
  { id: 'small', type: 'Small' },
  { id: 'medium', type: 'Medium' },
  { id: 'large', type: 'Large' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ViewDepartmentInput() {
  const params = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [state, setState] = useState(false);

  const [loader, setLoader] = useState(true);

  const [showPassword, setShowPassword] = useState(false);

  const [newUser, setNewUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    type: '',
    password: 'password123',
  });

  const [msg, setMsg] = useState();
  const [deptInput, setDeptInput] = useState(null);
  const [deptLevels, setDeptLevels] = useState([]);
  const [lecturers, setLecturers] = useState([]);

  const [token, setToken] = useState(view()._token);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const deleteDeptInput = async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}admin/department/input/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setLoader(true);
      navigate(`/dashboard/department/${deptInput.department_level.dept_code}`);
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const handleUpdateDeptInput = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${BASE_URL}admin/department/input/${params.id}/update`, deptInput, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      navigate(`/dashboard/department/${deptInput.department_level.dept_code}`);
      setLoader(true);
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  useEffect(() => {
    async function fetchDepartmentInput() {
      if (loader) {
        const response = await axios.get(`${BASE_URL}admin/lecturer/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLecturers(response.data.data);

        const response2 = await axios.get(`${BASE_URL}admin/department/input/${params.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const response3 = await axios.get(`${BASE_URL}admin/department/level`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setDeptLevels(
          response3.data.data.filter((data) => data.dept_code === response2.data.data.department_level.dept_code)
        );

        setLoader(false);
        setDeptInput(response2.data.data);
      }
    }

    fetchDepartmentInput();
  }, [deptInput, deptLevels, lecturers, token, loader, params]);

  if (loader === true) {
    return (
      <div className="text-center p-5">
        <h3>Loading...</h3>
      </div>
    );
  }

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {`${deptInput.course_title}`}
          </Typography>
          <div>
            <Button
              variant="contained"
              onClick={() => deleteDeptInput(deptInput.id)}
              color="error"
              // startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Delete
            </Button>
          </div>
        </Stack>

        <Stack mb={3}>{msg}</Stack>

        <Card>
          <CardContent>
            <Stack spacing={3}>
              <Typography variant="h4" component="h2">
                Update Department Input
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {`${deptInput.department_level.level} (${deptInput.department_level.session} ${deptInput.department_level.semester} semester)`}
              </Typography>

              <form onSubmit={handleUpdateDeptInput}>
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    label="Course Title"
                    id="course_title"
                    value={deptInput.course_title}
                    onChange={(e) => setDeptInput({ ...deptInput, course_title: e.target.value })}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Course Unit"
                    id="course_unit"
                    value={deptInput.course_unit}
                    onChange={(e) => setDeptInput({ ...deptInput, course_unit: e.target.value })}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Course Code"
                    id="course_code"
                    value={deptInput.course_code}
                    onChange={(e) => setDeptInput({ ...deptInput, course_code: e.target.value })}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Lecture Hours"
                    id="lecture_hrs"
                    value={deptInput.lecture_hrs}
                    onChange={(e) => setDeptInput({ ...deptInput, lecture_hrs: e.target.value })}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Practical Hours"
                    id="practical_hrs"
                    value={deptInput.practical_hrs}
                    onChange={(e) => setDeptInput({ ...deptInput, practical_hrs: e.target.value })}
                  />
                  <TextField
                    fullWidth
                    label="Tutorial Hours"
                    id="tutorial_hrs"
                    value={deptInput.tutorial_hrs}
                    onChange={(e) => setDeptInput({ ...deptInput, tutorial_hrs: e.target.value })}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="type-label">Lecturer</InputLabel>
                    <Select
                      labelId="lecturer-label"
                      id="lecturer-label"
                      value={deptInput.lecturer_id}
                      label="Lecturer"
                      onChange={(e) => setDeptInput({ ...deptInput, lecturer_id: e.target.value })}
                      required
                    >
                      {lecturers.map(
                        (lecturer) =>
                          // lecturer.type === 'lecturer' &&
                          // lecturer.dept_code === deptInput.department_level.dept_code && (
                            <MenuItem key={lecturer.id} value={lecturer.id}>
                              {lecturer.name} ({lecturer.dept_code})
                            </MenuItem>
                          // )
                      )}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="technologist-label">Technologist</InputLabel>
                    <Select
                      labelId="technologist-label"
                      id="technologist-label"
                      value={deptInput.technologist}
                      label="Technologist"
                      onChange={(e) => setDeptInput({ ...deptInput, technologist: e.target.value })}
                    >
                      {lecturers.map(
                        (lecturer) =>
                          lecturer.type === 'technologist' &&
                          lecturer.dept_code === deptInput.department_level.dept_code && (
                            <MenuItem key={lecturer.id} value={lecturer.id}>
                              {lecturer.name} ({lecturer.dept_code})
                            </MenuItem>
                          )
                      )}
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    label="Nature of Practical"
                    id="nature_of_practical"
                    value={deptInput.nature_of_practical}
                    onChange={(e) => setDeptInput({ ...deptInput, nature_of_practical: e.target.value })}
                    required
                  />
                  <FormControl fullWidth>
                    <InputLabel id="type-label">Level</InputLabel>
                    <Select
                      labelId="deptlevel-label"
                      id="deptlevel-label"
                      value={deptInput.dept_level_id}
                      label="Level"
                      onChange={(e) => setDeptInput({ ...deptInput, dept_level_id: e.target.value })}
                      required
                    >
                      {deptLevels.map((level) => (
                        <MenuItem key={level.id} value={level.id}>
                          {level.level} ({level.session} {level.semester} semester)
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                {/* <Stack alignItems="left" spacing={3}> */}

                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={3}>
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </Stack>
              </form>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
