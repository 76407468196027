import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import '../layouts/dashboard/pdf.css';

// import DeptLevelOutputs from './DeptLevelOutputs';
import { BASE_URL, deptLevels, days_, times, times_, session } from '../utils/data';
import { view } from '../utils/auth';
import {
  semester,
  deptLevelOutput,
  deptLevelAllocation,
  getTable,
  lectureDayAndTime,
  viewLecturerAllocations,
} from '../utils/timetable';

// import  from 'react';

export default function LecturerOutputDownload() {
  const params = useParams();
  const [lecturerAllocations, setLecturerAllocations] = useState([]);
  const [lecturer, setLecturer] = useState(null);
  const [dept, setDept] = useState(null);
  const [token, setToken] = useState(view()._token);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (isLoading) {
        const response = await axios.get(`${BASE_URL}admin/allocation/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // const dept = response.data.data[0].department_level.dept_code;
        const response2 = await axios.get(`${BASE_URL}admin/lecturer/${params.id}/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const response3 = await axios.get(`${BASE_URL}admin/department/${response2.data.data.dept_code}/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setLecturerAllocations(
          response.data.data.filter((data) => viewLecturerAllocations(params.id, data.lecturer_id) === 1)
        );

        setLecturer(response2.data.data);
        setDept(response3.data.data);
        setIsLoading(false);
        console.log(response.data.data.filter((data) => viewLecturerAllocations(params.id, data.lecturer_id) === 1));
      }
    }
    fetchData();
  }, [lecturerAllocations, lecturer, dept, isLoading, token, params]);

  console.log(lecturerAllocations);
  if (isLoading) {
    return <>Loading</>;
  }
  return (
    <>
      <div id="print" className="mt-20">
        <h3 className="text-center">LECTURE TIME TABLE</h3>
        <LecturerLectureView dept={dept} lecturerAllocations={lecturerAllocations} lecturer={lecturer} />
      </div>
    </>
  );
}

function LecturerLectureView({ dept, lecturerAllocations, lecturer }) {
  return <LecturerLectureOutputs dept={dept} lecturerAllocations={lecturerAllocations} lecturer={lecturer} />;
}

function LecturerLectureOutputs({ dept, lecturerAllocations, lecturer }) {
  // console.log(deptLevelAllocation(deptAllocations));
  const params = useParams();
  const [allocations, setAllocations] = useState(getTable(lectureDayAndTime(lecturerAllocations)));

  console.log(lecturerAllocations);
  console.log(allocations);
  console.log(lectureDayAndTime(lecturerAllocations));
  const [loading, setLoading] = useState(true);

  function formatTimeTableLevel(dept, level, mode, courseOption) {
    // CS, HND/ND II, true/false
    // -> HCS II or NCS II
    if (level.substring(0, 1) === 'H' && !mode) return "HND can't have PT";

    return mode
      ? `${level.substring(0, 1)}${dept} ${level.split(' ')[1]} ${courseOption && courseOption.substring(0, 4)}`
      : `P${level.substring(0, 1)}${dept} ${level.split(' ')[1]}`;
  }
  // useEffect(() => {
  //   if (loading) {
  //     const _allocations = getTable(deptAllocations.filter((allocation) => deptLevel === deptLevelOutput(allocation)));
  //     setAllocations(_allocations);
  //     console.log(_allocations);
  //     console.log(_allocations.monday[0].department_input.course_code);
  //     setLoading(false);

  //     // console.log(getTable(deptAllocations.filter((allocation) => deptLevel === deptLevelOutput(allocation))));
  //   }
  // }, [allocations, loading, deptAllocations, deptLevel]);

  // setAllocations(getTable(deptAllocations.filter((allocation) => deptLevel === deptLevelOutput(allocation))));
  return (
    <>
      {
        <>
          <div className="timetable">
            <div className="timetable-level">
              <div className="mt-20">
                <div className="row">
                  <div className="col-one-third">NAME : {lecturer.name.toUpperCase()}</div>
                  <div className="col-one-third">{''}</div>
                  <div className="col-one-third">DEPARTMENT: {dept.name.toUpperCase()}</div>
                </div>
              </div>

              <div className="my-20 font-bold">No: {lecturer.id}</div>

              <table className="table">
                <tr>
                  <td className="tdata">{''}</td>
                  <td className="tdata">8 - 9</td>
                  <td className="tdata">9 - 10</td>
                  <td className="tdata">10 - 11</td>
                  <td className="tdata">11 - 12</td>
                  <td className="tdata">12 - 1</td>
                  <td className="tdata">1 - 2</td>
                  <td className="tdata">2 - 3</td>
                  <td className="tdata">3 - 4</td>
                  <td className="tdata">4 - 5</td>
                  <td className="tdata">5 - 6</td>
                  <td className="tdata">6 - 7</td>
                  <td className="tdata">7 - 8</td>
                </tr>
                {days_.map((day) => (
                  <tr key={day[0]}>
                    {/* {deptAllocations.map((deptAllocation) =>
                        deptAllocation.day === day[1] ? ( */}
                    <>
                      <td className="tdataWeek font-bold">{day[0].toUpperCase()}</td>

                      {allocations[day[1]].map((allocation) =>
                        Object.keys(allocation).length > 0 ? (
                          <td key={allocation.id} className="tdata">
                            <div className="venueAndLecturerCode">
                              <span>{allocation.classroom !== null && allocation.classroom.venue}</span>{' '}
                              <span className="lecturerCode">
                                {allocation.classes !== null
                                  ? allocation.classes
                                  : formatTimeTableLevel(
                                      allocation.department_level.dept_code,
                                      allocation.department_level.level,
                                      allocation.department_level.ft,
                                      allocation.department_level.course_option
                                    )}
                              </span>
                            </div>
                            <div className="courseCode">{allocation.department_input.course_code}</div>
                          </td>
                        ) : (
                          // )
                          <td className="tdata">{''}</td>
                        )
                      )}
                    </>
                  </tr>
                ))}
              </table>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
        </>
      }
    </>
  );
}

/**
 * Get lecture
 * @param {*} day
 * @param {*} time
 * @param {*} departmentAllocations
 * @returns
 */
const getLecture = (day, time, departmentAllocations, deptLevel) => {
  const allocations = departmentAllocations.filter(
    (allocation) => allocation.day === day && deptLevel === deptLevelOutput(allocation)
  );

  let cellCount = 0;
  let allocationDetails = null;
  for (let index = 0; index < allocations.length; index += 1) {
    const allocation = allocations[index];
    const isTimeFound = allocation.time.split('-').includes(time);

    if (isTimeFound && allocation.time.split('-').indexOf(time) === 0) {
      const time_ = allocation.time;
      const timeArr = time_.split('-');
      const startTime = getTime(0, timeArr[0]);
      const endTime = getTime(0, timeArr[1]);

      const hr = endTime[1] - startTime[1];
      cellCount = hr;
      allocationDetails = allocation;
    }
  }
  return {
    cellCount,
    allocation: allocationDetails,
  };
};

const getTime = (index, selectedTime) => {
  const timeArr = times.filter((time) => time[index] === selectedTime);
  return timeArr[0];
};
