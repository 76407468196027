import { filter, capitalize } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import { ShimmerTable } from 'react-shimmer-effects';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  CardContent,
  CardMedia,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Drawer,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
  Link,
} from '@mui/material';

import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';

import axios from 'axios';
import { useTheme } from '@mui/material/styles';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';

// utils
import { BASE_URL } from '../utils/data';
import { view } from '../utils/auth';

// ----------------------------------------------------------------------
export default function LecturerCsvImport() {
  const navigate = useNavigate();
  const [state, setState] = useState(false);

  const [loader, setLoader] = useState(null);

  const [msg, setMsg] = useState();
  const [alert, setAlert] = useState();
  const [csvFile, setCsvFile] = useState(null);

  const [token, setToken] = useState(view()._token);

  const handleCsv = async (file) => {
    console.log(file);
    setLoader(true);
    setAlert(
      <Button variant="outlined" color="primary" disabled size="small">
        {file[0].name}
      </Button>
    );
    setCsvFile(file[0]);
  };

  const handleSubmitCsv = async () => {
    const formdata = new FormData();
    formdata.append('doc', csvFile);
    try {
      let response = null;
      response = await axios.post(`${BASE_URL}admin/lecturer/data/import`, formdata, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(
        <Alert
          action={
            <Button component={RouterLink} to={'/dashboard/lecturers'} color="inherit" size="small">
              View all
            </Button>
          }
        >
          {response.data.msg}
        </Alert>
      );
      setCsvFile('');
      setAlert('');
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setState(false);
      setLoader(true);
    } catch (error) {
      console.log(error);
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const handleTruncateLecturers = async () => {
    const formdata = new FormData();
    formdata.append('doc', csvFile);
    try {
      if (window.confirm('Are you sure that you want to proceed with this action? Pls note that it is irreversible.')) {
        let response = null;
        response = await axios.get(`${BASE_URL}admin/lecturer/truncate`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMsg(<Alert>{response.data.msg}</Alert>);
        navigate('/dashboard/lecturers');
      }
    } catch (error) {
      console.log(error);
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const theme = useTheme();

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Import Lecturers' file
          </Typography>
          <Button variant="contained" color="error" type="submit" onClick={() => handleTruncateLecturers()}>
            Truncate
          </Button>
        </Stack>

        <Stack mb={3}>{msg}</Stack>

        <Card>
          <CardContent>
            <center>
              <img src="/static/csv.png" width="200" alt="csv" />
              <br />
              <Typography variant="subtitle1">Upload a csv file</Typography>
              <br />
              <Button
                variant="contained"
                color="success"
                component="label"
                startIcon={<Iconify icon="eva:file-text-outline" />}
              >
                Select file
                <input hidden accept=".csv" type="file" onChange={(e) => handleCsv(e.target.files)} />
              </Button>
              &nbsp;
              <Button variant="contained" type="submit" onClick={() => handleSubmitCsv()}>
                Save
              </Button>
              <br />
              <br />
              {alert}
            </center>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
