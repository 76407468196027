import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material
import { Box, Card, Link, Typography, Stack, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/Label';
import { ColorPreview } from '../../../components/color-utils';

// utils
import { BASE_URL } from '../../../utils/data';
import { view } from '../../../utils/auth';

// action
import { collectionType } from '../../../actions';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
  type: PropTypes.string,
};

export default function ShopProductCard({ product, type }) {
  const { id, name, image, image1, price, category } = product;
  const dispatch = useDispatch();
  const collectionTypeValue = useSelector((state) => state.collectiontype);

  const handleDeleteVariety = async (id) => {
    try {
      await axios.delete(`${BASE_URL}admin/variety/${id}`, {
        headers: {
          Authorization: `Bearer ${view()._token}`,
        },
      });

      dispatch(collectionType({ ...collectionTypeValue, loader: true }));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Card>
      {type !== 'collection' && (
        <>
          <Box sx={{ pt: '100%', position: 'relative' }}>
            {/* {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )} */}
            <ProductImgStyle alt={name} src={type === 'product' ? image1 : image} />
          </Box>

          <Stack spacing={2} sx={{ p: 3 }}>
            <Link to={`/dashboard/${type}/${id}`} color="inherit" underline="hover" component={RouterLink}>
              <Typography variant="subtitle2" noWrap>
                {name}
              </Typography>
            </Link>

            {type === 'product' && (
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                {/* <ColorPreview colors={colors} /> */}
                <Typography variant="caption" noWrap>
                  {category !== null && category.name}
                </Typography>
                <Typography variant="subtitle1">
                  {/* <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through',
              }}
            >
              {priceSale && fCurrency(priceSale)}
            </Typography> */}
                  {/* &nbsp;  */}₦{fCurrency(price)}
                </Typography>
              </Stack>
            )}
          </Stack>
        </>
      )}
      {type === 'collection' && (
        <>
          <Box sx={{ pt: '100%', position: 'relative' }}>
            <Button
              variant="contained"
              color="error"
              sx={{
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
              }}
              size="small"
              onClick={() => handleDeleteVariety(id)}
            >
              Remove
            </Button>
            {/* <Label
              variant="filled"
              color={'info'}
              sx={{
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
              }}
              style={{ cursor: 'pointer' }}
            >
              Remove
            </Label> */}
            <ProductImgStyle alt={product.item.name} src={product.item.image1} />
          </Box>

          <Stack spacing={2} sx={{ p: 3 }}>
            <Link to={`/dashboard/product/${product.item.id}`} color="inherit" underline="hover" component={RouterLink}>
              <Typography variant="subtitle2" noWrap>
                {product.item.name}
              </Typography>
            </Link>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {/* <ColorPreview colors={colors} /> */}

              <Typography variant="subtitle1">
                {/* <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through',
              }}
            >
              {priceSale && fCurrency(priceSale)}
            </Typography> */}
                {/* &nbsp;  */}₦{fCurrency(product.item.price)}
              </Typography>
            </Stack>
            {/* )} */}
          </Stack>
        </>
      )}
    </Card>
  );
}
