import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

import axios from 'axios';

// component
import Iconify from '../../../components/Iconify';
import { BASE_URL } from '../../../utils/data';
import { view } from '../../../utils/auth';
// ----------------------------------------------------------------------

export default function UserMoreMenu(props) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const [token, setToken] = useState(view()._token);
  const handleDeleteAllocation = async (id) => {
    const response = await axios.delete(`${BASE_URL}admin/allocation/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);

    alert('Allocation deleted successfully!');
    window.location = '/dashboard/allocations';
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {/* <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}

        {props.type === 'lecturer' && (
          <MenuItem
            component={RouterLink}
            to={`/dashboard/lecturer/${props.identity}`}
            sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {props.type === 'classroom' && (
          <MenuItem
            component={RouterLink}
            to={`/dashboard/classroom/${props.identity}`}
            sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {props.type === 'department' && (
          <MenuItem
            component={RouterLink}
            to={`/dashboard/department/${props.identity}`}
            sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {props.type === 'department input' && (
          <MenuItem
            component={RouterLink}
            to={`/dashboard/department/input/${props.identity}`}
            sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {props.type === 'department level' && (
          <MenuItem
            component={RouterLink}
            to={`/dashboard/department/level/${props.identity}`}
            sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {props.type === 'lecture allocation' && (
          <MenuItem onClick={() => handleDeleteAllocation(props.identity)} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Iconify icon="eva:archive-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
