import { filter, capitalize } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import { ShimmerTable } from 'react-shimmer-effects';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Drawer,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import axios from 'axios';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

// utils
import { BASE_URL, days, times, session } from '../utils/data';
import { view } from '../utils/auth';
import { verify, getTime, getTimeFormat, getTimeDiff } from '../utils/timetable';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'course_code', label: 'Course Code', alignRight: false },
  { id: 'level', label: 'Level', alignRight: false },
  { id: 'lecturer_code', label: "Lecturer's code", alignRight: false },
  { id: 'venue', label: 'Venue', alignRight: false },
  { id: 'day', label: 'Day', alignRight: false },
  { id: 'time', label: 'Time', alignRight: false },
  { id: '' },
];

const classTypes = [
  { id: 'small', type: 'Small' },
  { id: 'medium', type: 'Medium' },
  { id: 'large', type: 'Large' },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.department_input.course_title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.department_input.course_code.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        // _user.department_level.level.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        // _user.dept_code.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        // _user.department_input.course_option.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.lecturer.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.lecturer.id === parseInt(query, 10) ||
        _user.classroom.venue.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.day.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.time.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.type === parseInt(query, 10)
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Allocation() {
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('id');

  const [filterName, setFilterName] = useState('');
  const [filterName2, setFilterName2] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [state, setState] = useState(false);

  const [loader, setLoader] = useState(null);

  const [showPassword, setShowPassword] = useState(false);

  const [newAllocation, setNewAllocation] = useState({
    dept_input_id: '',
    dept: '',
    dept_level_id: '',
    lecturer_id: '',
    day: '',
    from: '',
    to: '',
    time: '',
    classroom_id: '',
  });

  const [msg, setMsg] = useState();
  const [allocations, setAllocations] = useState([]);
  const [depts, setDepts] = useState([]);
  const [deptLevels, setDeptLevels] = useState([]);
  const [deptInputs, setDeptInputs] = useState([]);
  const [deptCourses, setDeptCourses] = useState([]);
  const [deptLecturer, setDeptLecturer] = useState({});
  const [classrooms, setClassrooms] = useState([]);

  const [deptLevel, setDeptLevel] = useState(null);
  const [deptInput, setDeptInput] = useState({});

  const [deptInputsData, setDeptInputsData] = useState([]);
  const [lecturers, setLecturers] = useState([]);
  const [lecturers_, setLecturers_] = useState([]);

  const [filteredCourses, setFilteredCourses] = useState([]);
  const [generalCoursesSelection, setGeneralCoursesSelection] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);

  const [generalCourses, setGeneralCourses] = useState([]);
  const [token, setToken] = useState(view()._token);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allocations.map((n) => n.venue);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleFilterByName2 = (event) => {
    setFilterName2(event.target.value);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allocations.length) : 0;

  let filteredUsers = applySortFilter(allocations, getComparator(order, orderBy), filterName);
  filteredUsers = applySortFilter(filteredUsers, getComparator(order, orderBy), filterName2);

  const isUserNotFound = filteredUsers.length === 0;

  const AlertMessage = (status, msg) => {
    const alertMsg = <Alert severity={status}>{msg}</Alert>;
    setMsg(alertMsg);
  };

  const handleNewAllocation = async (e) => {
    e.preventDefault();
    // console.log(deptInput);
    // console.log(newAllocation.from);
    // console.log(newAllocation.to);
    console.log(newAllocation);
    const from$ = getTime(1, newAllocation.from)[0];
    const to$ = getTime(1, newAllocation.to)[0];
    const response = verify({ ...newAllocation, ...deptInput });

    delete newAllocation.dept;

    const time = `${from$}-${to$}`;

    delete newAllocation.from;
    delete newAllocation.to;

    let allocation = {
      ...newAllocation,
      time,
    };

    if (deptInput.type === 'general') {
      allocation = {
        ...allocation,
        dept_input_id: selectedCourses,
      };
    }

    if (response.error === 1) {
      console.log(response);
      AlertMessage('error', response.msg);
    } else {
      try {
        console.log(allocation);
        let response = null;
        response = await axios.post(`${BASE_URL}admin/allocation/create`, allocation, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response);

        setNewAllocation({
          ...newAllocation,
          dept_input_id: '',
          dept_level_id: '',
          lecturer_id: '',
          classroom_id: '',
        });
        setGeneralCoursesSelection([]);
        setSelectedCourses([]);
        setMsg(<Alert severity="success">{response.data.msg}</Alert>);
        setTimeout(() => {
          setMsg('');
        }, 5000);
        setState(false);
        setLoader(true);
      } catch (error) {
        console.log(error);
        setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
        setTimeout(() => {
          setMsg('');
        }, 3000);
      }
    }
  };

  const handleAllocationClear = () => {
    setNewAllocation({
      ...newAllocation,
      day: '',
      time: '',
      dept_input_id: '',
      dept_level_id: '',
      lecturer_id: '',
      classroom_id: '',
    });
    setGeneralCoursesSelection([]);
    setSelectedCourses([]);
  };

  const handleDeleteUser = async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}admin/remove/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setLoader(true);
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const fetchDepartmentLevels = async (dept) => {
    setNewAllocation({ ...newAllocation, dept });

    const response = await axios.get(`${BASE_URL}admin/department/level?dept_code=${dept}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setDeptLevels(response.data.data);
  };

  const fetchDepartmentInputs = async (deptlevel) => {
    setNewAllocation({ ...newAllocation, dept_level_id: deptlevel });
    setDeptLevel(deptLevels.filter((el) => el.id === deptlevel)[0]);

    const response = await axios.get(`${BASE_URL}admin/department/input?dept_level_id=${deptlevel}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    setDeptInputs(response.data.data);
  };

  const fetchDeptLecturer = async (deptInput) => {
    const response = await axios.get(`${BASE_URL}admin/department/input/${deptInput}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const courseDetails = deptInputs.filter((el) => el.id === deptInput);
    // console.log(courseDetails);
    if (courseDetails[0].type === 'general') {
      const response2 = await axios.get(
        `${BASE_URL}admin/department/input?course_code=${courseDetails[0].course_code}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assign general courses that have the number of students to be > 0
      const generalCoursesByLecturer = response2.data.data.filter((data) => data.department_level.no_of_students !== 0);
      setGeneralCourses(generalCoursesByLecturer);
      // console.log(generalCoursesByLecturer);

      const deptInputsData = [];
      const lecturersDetails = [];

      if (!response.data.data.lecturer_id) {
        setLecturers([]);
        setDeptInputsData([]);
      } else {
        const lecturerCodes =
          response.data.data.lecturer_id.indexOf(',') !== -1
            ? response.data.data.lecturer_id.split(',')
            : [response.data.data.lecturer_id];

        for (let h = 0; h < lecturerCodes.length; h += 1) {
          const lecturerCode = lecturerCodes[h];
          console.log(lecturerCode);
          // console.log(lecturers_[0].id);
          const _lecturerDetails = lecturers_.filter((lecturer) => lecturer.id === parseInt(lecturerCode, 10));
          // console.log(lecturerDetails);
          lecturersDetails.push(_lecturerDetails[0]);
        }
        setLecturers(lecturersDetails);
        console.log(lecturersDetails);

        for (let i = 0; i < generalCoursesByLecturer.length; i += 1) {
          const course = generalCoursesByLecturer[i];

          deptInputsData.push({ dept_input_id: course.id });
          // lecturers.push({ lecturer_id: course.lecturer_id });
        }
        setDeptInputsData(deptInputsData);
        setNewAllocation({ ...newAllocation, dept_input_id: deptInput, lecturer_id: '' });
      }

      // setLecturers(lecturers);
    } else {
      const lectId = !response.data.data.lecturer_id ? '' : response.data.data.lecturer_id;

      setLecturers([]);

      setNewAllocation({ ...newAllocation, dept_input_id: deptInput, lecturer_id: lectId });
    }

    setDeptInput(response.data.data);
    // setNewAllocation({ ...newAllocation, dept_input_id: deptInput, lecturer_id: response.data.data.lecturer_id });
    setDeptLecturer(response.data.data);
  };

  const fetchLecturerCourses = (e) => {
    const lecturerId = e.target.value;
    // console.log(generalCourses);
    const filteredGeneralCourses = generalCourses.filter((course) => course.lecturer_id === String(lecturerId));
    console.log(filteredGeneralCourses);
    setFilteredCourses(filteredGeneralCourses);

    setNewAllocation({ ...newAllocation, lecturer_id: lecturerId });

    const deptInputsData_ = [];
    for (let i = 0; i < filteredGeneralCourses.length; i += 1) {
      const course = filteredGeneralCourses[i];
      deptInputsData_.push({ dept_input_id: course.id });
    }

    console.log(deptInputsData_);
    setDeptInputsData(deptInputsData_);
  };

  const handleGeneralCoursesSelection = (event) => {
    const {
      target: { value },
    } = event;
    const courseVal = typeof value === 'string' ? value.split(',') : value;

    const selectDeptInputs = [];

    for (let i = 0; i < courseVal.length; i += 1) {
      const course = courseVal[i].split(' ');
      const splitCourse0 = course[0].split('-');

      const filteredSelectedInput = filteredCourses.filter(
        (fCourse) =>
          fCourse.department_level.dept_code === splitCourse0[0] &&
          // fCourse.department_level.ft === splitCourse0[1] &&
          fCourse.department_level.course_option === splitCourse0[2].split('_').join(' ')
      );
      selectDeptInputs.push(filteredSelectedInput[0].id);
    }

    setGeneralCoursesSelection(
      // On autofill we get a stringified value.
      courseVal
    );

    setSelectedCourses(selectDeptInputs);
  };

  const handleDropAllocations = async () => {
    try {
      if (window.confirm('Are you sure you want to perform this? This action is irreversible')) {
        const response = await axios.get(`${BASE_URL}admin/allocation/truncate`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMsg(<Alert severity="success">{response.data.msg}</Alert>);
        setTimeout(() => {
          setMsg('');
        }, 5000);
        setLoader(true);
      }
    } catch (error) {
      console.log(error);
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  const lecturerCodes = (lecturer, lecturerId) => {
    console.log(lecturer);
    console.log(lecturerId);
    if (!lecturer) {
      return '-';
    }

    if (lecturerId.indexOf(',') !== -1) {
      return lecturerId;
    }

    return `${lecturer.name} (${lecturer.id})`;
  };

  const remainingAllocationForClassroom = (classroom, _allocations) => {
    const allocations_ = _allocations.filter((allocation) => allocation.classroom_id === classroom.id);
    let totalHrs = 0;
    const NotGeneralAllocations_ = allocations_.filter((allocation) => allocation.department_input.type !== 'general');
    const GeneralAllocations_ = allocations_.filter((allocation) => allocation.department_input.type === 'general');

    if (NotGeneralAllocations_.length > 0) {
      for (let i = 0; i < NotGeneralAllocations_.length; i += 1) {
        const allocation_ = NotGeneralAllocations_[i];

        const startTimeArr = getTime(0, allocation_.time.split('-')[0]);
        const stopTimeArr = getTime(0, allocation_.time.split('-')[1]);
        totalHrs += getTimeDiff(startTimeArr, stopTimeArr);
      }
    }

    const DistinctGeneralAllocation = [];
    if (GeneralAllocations_.length > 0) {
      for (let i = 0; i < GeneralAllocations_.length; i += 1) {
        const allocation_ = GeneralAllocations_[i];
        if (DistinctGeneralAllocation.length === 0) {
          DistinctGeneralAllocation.push(allocation_);
        } else {
          const dGeneralAllLength = DistinctGeneralAllocation.filter(
            (dAllocation) =>
              dAllocation.lecturer_id === allocation_.lecturer_id &&
              dAllocation.department_input.course_code === allocation_.department_input.course_code &&
              dAllocation.time === allocation_.time &&
              dAllocation.day === allocation_.day
          );
          if (dGeneralAllLength.length === 0) {
            DistinctGeneralAllocation.push(allocation_);
          }
        }
      }
    }

    if (DistinctGeneralAllocation.length > 0) {
      for (let i = 0; i < DistinctGeneralAllocation.length; i += 1) {
        const allocation_ = DistinctGeneralAllocation[i];

        const startTimeArr = getTime(0, allocation_.time.split('-')[0]);
        const stopTimeArr = getTime(0, allocation_.time.split('-')[1]);
        totalHrs += getTimeDiff(startTimeArr, stopTimeArr);
      }
    }

    return classroom.hours - totalHrs;
  };

  useEffect(() => {
    async function fetchAllocations() {
      setLoader(true);
      const response = await axios.get(`${BASE_URL}admin/allocation/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response2 = await axios.get(`${BASE_URL}admin/department/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const response3 = await axios.get(`${BASE_URL}admin/classroom`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const response4 = await axios.get(`${BASE_URL}admin/lecturer/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setLoader(false);
      setAllocations(response.data.data);
      setDepts(response2.data.data);
      // setDeptCourses(response02.data.data);
      setClassrooms(response3.data.data);
      setLecturers_(response4.data.data);

      // console.log(deptCourses)
    }
    if (loader !== false) {
      fetchAllocations();
    }
  }, [allocations, classrooms, lecturers_, depts, token, loader]);

  // console.log(deptLecturer);
  // console.log(newAllocation);
  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Lecture Allocations (Omnibus)
          </Typography>
          <div>
            <Button
              variant="contained"
              onClick={toggleDrawer('right', true)}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Allocation
            </Button>
            &nbsp;
            {/* <Button
              variant="contained"
              onClick={() => handleDropAllocations()}
              color="error"
              startIcon={<Iconify icon="eva:archive-fill" />}
            >
              Truncate
            </Button> */}
          </div>
        </Stack>

        <Stack mb={3}>{msg}</Stack>

        <Drawer anchor={'right'} open={state} onClose={toggleDrawer('right', false)}>
          <Box
            sx={{ width: 400 }}
            role="presentation"
            // onClick={toggleDrawer('right', false)}
            // onKeyDown={toggleDrawer('right', false)}
          >
            <Container style={{ paddingTop: 20 }}>
              <Stack spacing={3}>
                <Typography variant="h4" component="h2">
                  Perform Lecture Allocation
                </Typography>

                {msg}
                <form onSubmit={handleNewAllocation}>
                  <Stack spacing={2}>
                    <FormControl fullWidth>
                      <InputLabel id="day-label">Day</InputLabel>
                      <Select
                        labelId="day-label"
                        id="day-label"
                        value={newAllocation.day}
                        label="Day"
                        placeholder="Select day"
                        onChange={(e) => setNewAllocation({ ...newAllocation, day: e.target.value })}
                        required
                      >
                        {days.map((day) => (
                          <MenuItem key={day} value={day}>
                            {capitalize(day)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <InputLabel id="from-label">From</InputLabel>
                        <Select
                          labelId="from-label"
                          id="from-label"
                          value={newAllocation.from}
                          label="From"
                          onChange={(e) => setNewAllocation({ ...newAllocation, from: e.target.value })}
                          required
                        >
                          {times.map((time) => (
                            <MenuItem key={time[1]} value={time[1]}>
                              {time[0]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel id="to-label">To</InputLabel>
                        <Select
                          labelId="to-label"
                          id="to-label"
                          value={newAllocation.to}
                          label="To"
                          onChange={(e) => setNewAllocation({ ...newAllocation, to: e.target.value })}
                          required
                        >
                          {times.map((time) => (
                            <MenuItem key={time[1]} value={time[1]}>
                              {time[0]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                    <FormControl fullWidth>
                      <InputLabel id="dept-label">Department</InputLabel>
                      <Select
                        labelId="dept-label"
                        id="dept-label"
                        value={newAllocation.dept}
                        label="Department"
                        onChange={(e) => fetchDepartmentLevels(e.target.value)}
                        required
                      >
                        {depts.map((dept) => (
                          <MenuItem key={dept.id} value={dept.id}>
                            {dept.name} ({dept.id})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="deptlevel-label">Department level</InputLabel>
                      <Select
                        labelId="deptlevel-label"
                        id="deptlevel-label"
                        value={newAllocation.dept_level_id}
                        label="Department level"
                        onChange={(e) => fetchDepartmentInputs(e.target.value)}
                        required
                      >
                        {deptLevels.map((level) => (
                          <MenuItem key={level.id} value={level.id}>
                            {level.level} {level.ft ? 'FT' : 'PT'} {level.course_option !== null && level.course_option}
                            ({level.session} {level.semester} semester)
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="deptinput-label">Department input</InputLabel>
                      <Select
                        labelId="deptinput-label"
                        id="deptinput-label"
                        value={
                          Array.isArray(newAllocation.dept_input_id)
                            ? newAllocation.dept_input_id[1].dept_input_id
                            : newAllocation.dept_input_id
                        }
                        label="Department input"
                        onChange={(e) => fetchDeptLecturer(e.target.value)}
                        required
                      >
                        {deptInputs.map((input) => (
                          <MenuItem key={input.id} value={input.id}>
                            {input.course_code} ( {input.course_unit} unit(s)/{input.lecture_hrs}hr(s) )
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="lecturer-label">Lecturer</InputLabel>
                      <Select
                        labelId="lecturer-label"
                        id="lecturer-label"
                        value={newAllocation.lecturer_id}
                        label="Lecturer"
                        disabled={!(Object.keys(deptInput).length > 0 && deptInput.type === 'general')}
                        onChange={fetchLecturerCourses}
                      >
                        {Object.keys(deptInput).length > 0 &&
                          deptInput.type === 'general' &&
                          lecturers.length > 0 &&
                          lecturers.map((lecturer) => (
                            <MenuItem key={lecturer.id} value={lecturer.id}>
                              {lecturer.name} ({lecturer.id})
                            </MenuItem>
                          ))}
                        {Object.keys(deptLecturer).length > 0 && lecturers.length === 0 ? (
                          <MenuItem value={deptInput.lecturer_id}>
                            {lecturerCodes(deptLecturer.lecturer, newAllocation.lecturer_id)}
                          </MenuItem>
                        ) : (
                          ''
                        )}
                      </Select>
                    </FormControl>
                    {Object.keys(deptInput).length > 0 && deptInput.type === 'general' && (
                      <>
                        <FormControl fullWidth>
                          <InputLabel id="demo-multiple-chip-label">
                            Classes offering {deptInput.course_code}
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={generalCoursesSelection}
                            onChange={handleGeneralCoursesSelection}
                            input={
                              <OutlinedInput
                                id="select-multiple-chip"
                                label={`Classes offering ${deptInput.course_code}`}
                              />
                            }
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {filteredCourses.length > 0 &&
                              filteredCourses.map((course) => (
                                <MenuItem
                                  key={course.id}
                                  value={`${course.department_level.dept_code}-${
                                    course.department_level.ft ? 'FT' : 'PT'
                                  }-${course.department_level.course_option.split(' ').join('_')} ${
                                    course.department_level.level
                                  }`}
                                  style={getStyles(
                                    `${course.department_level.dept_code} (${course.department_level.no_of_students})`,
                                    generalCoursesSelection,
                                    theme
                                  )}
                                >
                                  {course.department_level.dept_code}-{course.department_level.course_option}{' '}
                                  {course.department_level.ft ? 'FT' : 'PT'} ({course.department_level.no_of_students})
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </>
                    )}

                    <FormControl fullWidth>
                      <InputLabel id="classroom-label">Available Classrooms</InputLabel>
                      <Select
                        labelId="classroom-label"
                        id="classroom-label"
                        value={newAllocation.classroom_id}
                        label="Available Classrooms"
                        onChange={(e) => setNewAllocation({ ...newAllocation, classroom_id: e.target.value })}
                      >
                        {classrooms.map(
                          (classroom) =>
                            remainingAllocationForClassroom(classroom, allocations) > 0 && (
                              <MenuItem key={classroom.id} value={classroom.id}>
                                {classroom.venue} ({classroom.capacity}){' '}
                                {classroom.hours &&
                                  `${remainingAllocationForClassroom(classroom, allocations)} hr(s) remaining`}
                              </MenuItem>
                            )
                        )}
                      </Select>
                    </FormControl>

                    {/* <TextField fullWidth label="Session" id="session" value={session} disabled /> */}
                  </Stack>
                  {/* <Stack alignItems="left" spacing={3}> */}

                  <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={3} mb={2}>
                    <Button variant="contained" type="button" onClick={handleAllocationClear}>
                      Clear
                    </Button>

                    <Button variant="contained" type="submit">
                      Proceed
                    </Button>
                  </Stack>
                </form>
              </Stack>
            </Container>
          </Box>
        </Drawer>

        <Card>
          {loader === true ? (
            <ShimmerTable row={5} col={5} />
          ) : (
            <>
              <Stack direction="row">
                <UserListToolbar
                  index="1"
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />
                <UserListToolbar
                  index="2"
                  numSelected={selected.length}
                  filterName={filterName2}
                  onFilterName={handleFilterByName2}
                />
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={allocations.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const {
                          id,
                          departmentInput,
                          departmentLevel,
                          lecturer,
                          lecturerId,
                          classroom,
                          venue,
                          day,
                          time,
                        } = camelcaseKeys(row);
                        const isItemSelected = selected.indexOf(venue) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, venue)} />
                            </TableCell>
                            {/* <TableCell component="th" scope="row" padding="none">
                              {$index}
                            </TableCell> */}
                            <TableCell component="th" align="left">
                              <Typography variant="subtitle2" noWrap>
                                {!departmentInput
                                  ? '-'
                                  : `${departmentInput.course_code} (${departmentInput.course_unit})`}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              {!departmentLevel
                                ? '-'
                                : `${departmentLevel.dept_code} ${departmentLevel.level} ${
                                    departmentLevel.ft ? 'FT' : 'PT'
                                  } ${departmentLevel.course_option !== null && departmentLevel.course_option} (${
                                    departmentLevel.semester
                                  } semester ${departmentLevel.session})`}
                            </TableCell>
                            <TableCell align="left">{lecturerCodes(lecturer, lecturerId)}</TableCell>
                            <TableCell align="left">
                              {!classroom ? '-' : `${classroom.venue} (${classroom.capacity})`}
                            </TableCell>
                            <TableCell align="left">{capitalize(day)}</TableCell>
                            <TableCell align="left">{time}</TableCell>

                            <TableCell align="right">
                              <UserMoreMenu type="lecture allocation" identity={id} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={8} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={allocations.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
