import { filter, capitalize } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import { ShimmerTable } from 'react-shimmer-effects';

import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Drawer,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
} from '@mui/material';

import axios from 'axios';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

// utils
import { BASE_URL } from '../utils/data';
import { view } from '../utils/auth';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Code', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilterForDepartment(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_dept) =>
        _dept.id.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _dept.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Department() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [state, setState] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [newDept, setNewDept] = useState({
    id: '',
    name: '',
  });

  const [msg, setMsg] = useState();
  const [departments, setDepartments] = useState([]);

  const [loader, setLoader] = useState(null);

  const [token, setToken] = useState(view()._token);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = departments.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - departments.length) : 0;

  const filteredUsers = applySortFilterForDepartment(departments, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const handleNewDept = async (e) => {
    e.preventDefault();
    try {
      let response = null;
      response = await axios.post(`${BASE_URL}admin/department/create`, newDept, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      newDept.id = '';
      newDept.name = '';
      setMsg(<Alert severity="success">{response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 5000);
      setState(false);
      setLoader(true);
    } catch (error) {
      setMsg(<Alert severity="error">{error.response.data.msg}</Alert>);
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  useEffect(() => {
    async function fetchDepartments() {
      setLoader(true);
      const response = await axios.get(`${BASE_URL}admin/department/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoader(false);
      setDepartments(response.data.data);
    }
    if (loader !== false) {
      fetchDepartments();
    }
  }, [departments, loader, token]);

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Departments
          </Typography>
          <div>
            <Button
              component={RouterLink}
              to={'/dashboard/department/import/csv'}
              variant="contained"
              color="success"
              startIcon={<Iconify icon="eva:cloud-upload-outline" />}
            >
              Import data
              {/* <input hidden accept=".csv" type="file" /> */}
            </Button>
            &nbsp;
            <Button
              variant="contained"
              onClick={toggleDrawer('right', true)}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Department
            </Button>
          </div>
        </Stack>

        <Stack mb={3}>{msg}</Stack>

        <Drawer anchor={'right'} open={state} onClose={toggleDrawer('right', false)}>
          <Box
            sx={{ width: 400 }}
            role="presentation"
            // onClick={toggleDrawer('right', false)}
            // onKeyDown={toggleDrawer('right', false)}
          >
            <Container style={{ paddingTop: 20 }}>
              <Stack spacing={3}>
                <Typography variant="h4" component="h2">
                  Create Department
                </Typography>

                {msg}
                <form onSubmit={handleNewDept}>
                  <Stack spacing={2}>
                    <TextField
                      fullWidth
                      label="Code"
                      id="id"
                      value={newDept.id}
                      onChange={(e) => setNewDept({ ...newDept, id: e.target.value })}
                      required
                    />
                    <TextField
                      fullWidth
                      label="Name"
                      id="name"
                      value={newDept.name}
                      onChange={(e) => setNewDept({ ...newDept, name: e.target.value })}
                      required
                    />
                  </Stack>
                  {/* <Stack alignItems="left" spacing={3}> */}

                  <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={3}>
                    <Button variant="contained" type="submit">
                      Create
                    </Button>
                  </Stack>
                </form>
              </Stack>
            </Container>
          </Box>
        </Drawer>

        <Card>
          {loader === true ? (
            <ShimmerTable row={5} col={5} />
          ) : (
            <>
              <UserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={departments.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        // const { id, name, role, status, company, avatarUrl, isVerified } = camelCase(row);
                        const { id, name } = camelcaseKeys(row);
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} />
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                {/* <Avatar alt={name} src={avatarUrl} /> */}
                                <Typography variant="subtitle2" noWrap>
                                  {id}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                {/* <Avatar alt={name} src={avatarUrl} /> */}
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="right">
                              <UserMoreMenu type="department" identity={id} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={departments.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
