import { filter, capitalize } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Chartjs
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Line } from 'react-chartjs-2';

// material
import {
  Card,
  CardContent,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Drawer,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import axios from 'axios';
import {
  LineChart,
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// components
import Page from '../components/Page';

// utils
import { BASE_URL, days, times, session } from '../utils/data';
import { view } from '../utils/auth';
import { verify, getTime, getTimeFormat, getTimeDiff, divideArray, estimatedHrsLeft } from '../utils/timetable';

// ----------------------------------------------------------------------

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default function Analytics() {
  const theme = useTheme();

  const [loader, setLoader] = useState(true);

  const [allocations, setAllocations] = useState([]);

  const [classrooms, setClassrooms] = useState([]);

  const [filteredCourses, setFilteredCourses] = useState([]);
  const [generalCoursesSelection, setGeneralCoursesSelection] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);

  const [generalCourses, setGeneralCourses] = useState([]);
  const [token, setToken] = useState(view()._token);

  const [chunks, setChunks] = useState([]);

  const handleGeneralCoursesSelection = (event) => {
    const {
      target: { value },
    } = event;
    const courseVal = typeof value === 'string' ? value.split(',') : value;

    const selectDeptInputs = [];

    for (let i = 0; i < courseVal.length; i += 1) {
      const course = courseVal[i].split(' ');
      const filteredSelectedInput = filteredCourses.filter(
        (fCourse) => fCourse.department_level.dept_code === course[0]
      );
      selectDeptInputs.push(filteredSelectedInput[0].id);
    }
    console.log(selectDeptInputs);

    setGeneralCoursesSelection(
      // On autofill we get a stringified value.
      courseVal
    );

    setSelectedCourses(selectDeptInputs);
  };

  const lecturerCodes = (lecturer, lecturerId) => {
    console.log(lecturer);
    console.log(lecturerId);
    if (!lecturer) {
      return '-';
    }

    if (lecturerId.indexOf(',') !== -1) {
      return lecturerId;
    }

    return `${lecturer.name} (${lecturer.id})`;
  };

  const remainingAllocationForClassroom = (classroom, _allocations) => {
    const allocations_ = _allocations.filter((allocation) => allocation.classroom_id === classroom.id);
    let totalHrs = 0;
    const NotGeneralAllocations_ = allocations_.filter((allocation) => allocation.department_input.type !== 'general');
    const GeneralAllocations_ = allocations_.filter((allocation) => allocation.department_input.type === 'general');

    if (NotGeneralAllocations_.length > 0) {
      for (let i = 0; i < NotGeneralAllocations_.length; i += 1) {
        const allocation_ = NotGeneralAllocations_[i];

        const startTimeArr = getTime(0, allocation_.time.split('-')[0]);
        const stopTimeArr = getTime(0, allocation_.time.split('-')[1]);
        totalHrs += getTimeDiff(startTimeArr, stopTimeArr);
      }
    }

    const DistinctGeneralAllocation = [];
    if (GeneralAllocations_.length > 0) {
      for (let i = 0; i < GeneralAllocations_.length; i += 1) {
        const allocation_ = GeneralAllocations_[i];
        if (DistinctGeneralAllocation.length === 0) {
          DistinctGeneralAllocation.push(allocation_);
        } else {
          const dGeneralAllLength = DistinctGeneralAllocation.filter(
            (dAllocation) =>
              dAllocation.lecturer_id === allocation_.lecturer_id &&
              dAllocation.department_input.course_code === allocation_.department_input.course_code &&
              dAllocation.time === allocation_.time &&
              dAllocation.day === allocation_.day
          );
          if (dGeneralAllLength.length === 0) {
            DistinctGeneralAllocation.push(allocation_);
          }
        }
      }
    }

    if (DistinctGeneralAllocation.length > 0) {
      for (let i = 0; i < DistinctGeneralAllocation.length; i += 1) {
        const allocation_ = DistinctGeneralAllocation[i];

        const startTimeArr = getTime(0, allocation_.time.split('-')[0]);
        const stopTimeArr = getTime(0, allocation_.time.split('-')[1]);
        totalHrs += getTimeDiff(startTimeArr, stopTimeArr);
      }
    }

    return classroom.hours - totalHrs;
  };

  const labels = [];
  const [analyticsData, setAnalyticsData] = useState([]);
  const data = [];

  useEffect(() => {
    async function fetchAllocations() {
      setLoader(true);
      const response = await axios.get(`${BASE_URL}admin/allocation/view`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const response2 = await axios.get(`${BASE_URL}admin/classroom`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const classroomChunks = divideArray(response2.data.data, 10);

      console.log(classroomChunks);

      setAnalyticsData(classroomChunks);

      setLoader(false);
      setAllocations(response.data.data);
      setClassrooms(response2.data.data);
    }
    if (loader !== false) {
      fetchAllocations();
    }
  }, [allocations, classrooms, analyticsData, token, loader]);

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Analytics
          </Typography>
        </Stack>

        <Card>
          <CardContent>
            <Stack spacing={3}>{''}</Stack>
            {loader === true ? (
              <ShimmerThumbnail height={400} rounded />
            ) : (
              analyticsData.map((_aData) => {
                const _Data = estimatedHrsLeft(_aData, allocations);
                // console.log(_Data);
                return (
                  <ResponsiveContainer key={_aData[0].id} width="100%" height={400}>
                    <BarChart width={730} height={250} data={_Data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="Remaining Hrs" fill="#259955" />
                      {/* <Line type="monotone" dataKey="Remaining Hrs" stroke="#8884d8" /> */}
                      {/* </LineChart> */}
                    </BarChart>
                  </ResponsiveContainer>
                );
              })
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
