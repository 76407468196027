import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import '../layouts/dashboard/pdf.css';

// import DeptLevelOutputs from './DeptLevelOutputs';
import { BASE_URL, deptLevels, days_, times, times_, session } from '../utils/data';
import { view } from '../utils/auth';
import {
  semester,
  deptLevelOutput,
  deptLevelAllocation,
  getTable,
  lectureDayAndTime,
  viewLecturerAllocations,
  lecturerCodesForOutput,
} from '../utils/timetable';

export default function ClassroomOutputDownload() {
  const params = useParams();
  const [classroomAllocations, setClassroomAllocations] = useState([]);
  const [classroom, setClassroom] = useState(null);
  const [dept, setDept] = useState(null);
  const [token, setToken] = useState(view()._token);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (isLoading) {
        const response = await axios.get(`${BASE_URL}admin/allocation/view`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const response2 = await axios.get(`${BASE_URL}admin/classroom/${params.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setClassroomAllocations(response.data.data.filter((data) => data.classroom_id === parseInt(params.id, 10)));

        setClassroom(response2.data.data);
        // setDept(response3.data.data);
        setIsLoading(false);
        console.log(response.data.data.filter((data) => data.classroom_id === parseInt(params.id, 10)));
        console.log(response.data.data);
      }
    }
    fetchData();
  }, [classroomAllocations, classroom, dept, isLoading, token, params]);

  console.log(classroomAllocations);
  if (isLoading) {
    return <>Loading</>;
  }
  return (
    <>
      <div id="print" className="mt-20">
        <h3 className="text-center">LECTURE TIME TABLE</h3>
        <ClassroomLectureView classroomAllocations={classroomAllocations} classroom={classroom} />
      </div>
    </>
  );
}

function ClassroomLectureView({ classroomAllocations, classroom }) {
  return <ClassroomLectureOutputs classroomAllocations={classroomAllocations} classroom={classroom} />;
}

function ClassroomLectureOutputs({ classroomAllocations, classroom }) {
  // console.log(deptLevelAllocation(deptAllocations));
  const params = useParams();
  const [allocations, setAllocations] = useState(getTable(lectureDayAndTime(classroomAllocations)));

  console.log(classroomAllocations);
  console.log(allocations);
  console.log(lectureDayAndTime(classroomAllocations));
  const [loading, setLoading] = useState(true);

  function formatTimeTableLevel(dept, level, mode, courseOption) {
    // CS, HND/ND II, true/false
    // -> HCS II or NCS II
    if (level.substring(0, 1) === 'H' && !mode) return "HND can't have PT";

    return mode
      ? `${level.substring(0, 1)}${dept} ${level.split(' ')[1]} ${courseOption && courseOption.substring(0, 4)}`
      : `P${level.substring(0, 1)}${dept} ${level.split(' ')[1]}`;
  }
  // useEffect(() => {
  //   if (loading) {
  //     const _allocations = getTable(deptAllocations.filter((allocation) => deptLevel === deptLevelOutput(allocation)));
  //     setAllocations(_allocations);
  //     console.log(_allocations);
  //     console.log(_allocations.monday[0].department_input.course_code);
  //     setLoading(false);

  //     // console.log(getTable(deptAllocations.filter((allocation) => deptLevel === deptLevelOutput(allocation))));
  //   }
  // }, [allocations, loading, deptAllocations, deptLevel]);

  // setAllocations(getTable(deptAllocations.filter((allocation) => deptLevel === deptLevelOutput(allocation))));
  return (
    <>
      {
        <>
          <div className="timetable">
            <div className="timetable-level">
              <div className="mt-20">
                <div className="row">
                  <div className="col-one-third">Venue: {classroom.venue.toUpperCase()}</div>
                  <div className="col-one-third">{''}</div>
                </div>
              </div>

              <table className="table">
                <tr>
                  <td className="tdata">{''}</td>
                  <td className="tdata">8 - 9</td>
                  <td className="tdata">9 - 10</td>
                  <td className="tdata">10 - 11</td>
                  <td className="tdata">11 - 12</td>
                  <td className="tdata">12 - 1</td>
                  <td className="tdata">1 - 2</td>
                  <td className="tdata">2 - 3</td>
                  <td className="tdata">3 - 4</td>
                  <td className="tdata">4 - 5</td>
                  <td className="tdata">5 - 6</td>
                  <td className="tdata">6 - 7</td>
                  <td className="tdata">7 - 8</td>
                </tr>
                {days_.map((day) => (
                  <tr key={day[0]}>
                    {/* {deptAllocations.map((deptAllocation) =>
                        deptAllocation.day === day[1] ? ( */}
                    <>
                      <td className="tdataWeek font-bold">{day[0].toUpperCase()}</td>

                      {allocations[day[1]].map((allocation) =>
                        Object.keys(allocation).length > 0 ? (
                          <td key={allocation.id} className="tdata">
                            <div className="venueAndLecturerCode">
                              <span>
                                {lecturerCodesForOutput(allocation.lecturer, allocation.lecturer_id)}
                                {/* {allocation.classroom.venue} */}
                              </span>{' '}
                              <span className="lecturerCode">
                                {allocation.classes !== null
                                  ? allocation.classes
                                  : formatTimeTableLevel(
                                      allocation.department_level.dept_code,
                                      allocation.department_level.level,
                                      allocation.department_level.ft,
                                      allocation.department_level.course_option
                                    )}
                              </span>
                            </div>
                            <div className="courseCode">{allocation.department_input.course_code}</div>
                          </td>
                        ) : (
                          // )
                          <td className="tdata">{''}</td>
                        )
                      )}
                    </>
                  </tr>
                ))}
              </table>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
        </>
      }
    </>
  );
}
